import { Box, Button, Card, CardContent, CircularProgress, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import theme from '../theme'
import React, { useEffect } from 'react'
import ResumeTitle from './ResumeTitle'
import ResumeThumbnail from './ResumeThumbnail'
import { DeleteForever, Edit } from '@mui/icons-material'
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp'
import useUser from '../Hooks/useUser'
import { httpOnGetResumeThumbnail } from '../Services/Http'
import ConfirmDialogBox from './ConfirmDialogBox'
import { useNavigate } from 'react-router-dom'


interface ResumeCardProps {
    resumeB62Id: string,
    fileName: string,
    resumeUpdatedAt: string,
    onRemoveResume: (resumeB62Id: string) => void,
    removeResumeInProgress: boolean,
    isDisabled: boolean,
    displayNavigateToResources: boolean,
    isResumeStatusFailed: boolean,
    tailor_count?: number,
    onViewTailoredResumes?: () => void,
    showQuickViewButton?: boolean
}
  

const ResumeCard: React.FC<ResumeCardProps> = ({ resumeB62Id, fileName, resumeUpdatedAt,
  onRemoveResume, removeResumeInProgress, isDisabled, displayNavigateToResources, isResumeStatusFailed,
  tailor_count, onViewTailoredResumes, showQuickViewButton
}) => {

  const [resumeThumbnail, setResumeThumbnail] = React.useState<string>("");

  const { userId } = useUser();

  const navigate = useNavigate();

  const [removeResumeDialogOpen, setRemoveResumeDialogOpen] = React.useState<boolean>(false);

  useEffect(() => {
    if (resumeB62Id) {
      httpOnGetResumeThumbnail(resumeB62Id, userId)
      .then((res: any) => {
        const thumbnail = res?.data?.pdf_thumbnail || '';
        setResumeThumbnail(thumbnail);
      })
      .catch((e: any) => {
        console.error("Error fetching thumbnail:", {
          error: e?.message || e,
          status: e?.status,
          response: e?.data
        });
        setResumeThumbnail('');
      });
    } else {
      setResumeThumbnail('');
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeB62Id]);

  const editResume = (resumeB62Id: string)  =>  navigate(`/app/${resumeB62Id}/edit`);

  const navigateToResourcesView = (resumeB62Id: string)  =>  navigate(`/app/${resumeB62Id}/folder`);

  return (
    <Card
      sx={{ height: 1, alignContent: 'end', borderRadius: theme.spacing(1.5) }}>
      <CardContent sx={{
        display: 'flex', 
        flexDirection: 'column', 
        height: '100%',
        pt: 1,
        '&:last-child': {
          pb: 2
        }
      }}>

        <Box pb={1}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ResumeTitle fileName={fileName} resumeUpdatedAt={resumeUpdatedAt} />
          </Box>
        </Box>

        {/* <Divider /> */}

        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }} pb={1}>

          <Box pb={1} pt={1} sx={{ width: '40px' }}>
          </Box>

          <ResumeThumbnail thumbnailUrl={resumeThumbnail}
                            onClick={() => editResume(resumeB62Id)} isResumeStatusFailed={isResumeStatusFailed} />

          <Box pb={1} pt={1} sx={{ alignSelf: 'end', width: '40px', display: 'flex', flexDirection: 'column', gap: 1, ml: 2 }}>
            {showQuickViewButton && (
              <Tooltip title={tailor_count !== undefined && tailor_count > 0 
                ? "View tailored resumes created from current resume"
                : "No tailored resumes created yet"} placement="top" arrow>
                <span>
                  <IconButton
                    onClick={() => navigateToResourcesView(resumeB62Id)}
                    disabled={isDisabled || !tailor_count}
                    size="small"
                    color="primary"
                  >
                    <GridViewSharpIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <Tooltip title={'Edit original resume text.'} placement="top" arrow>
              <span>
                <IconButton color="primary" onClick={() => editResume(resumeB62Id)}
                  disabled={isDisabled || removeResumeInProgress || !resumeB62Id}
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={'Delete the resume'} placement="top" arrow>
              <span>
                <IconButton 
                  color="error" 
                  onClick={() => {setRemoveResumeDialogOpen(true)}} 
                  disabled={isDisabled || removeResumeInProgress}
                >
                  <DeleteForever />
                </IconButton>
              </span>
            </Tooltip>

            { removeResumeInProgress && <CircularProgress sx={{
              marginTop: '10px',
              width: '20px !important', height: '20px !important'}}/>
            }
          </Box>
        </Box>

        { displayNavigateToResources && <>
          <Divider />

          <Box sx={{'display': 'flex', p: 1}}>
            <Tooltip title={'View previously added job descriptions and resumes'} placement="top" arrow>
              <span>
                <Button sx={{margin: 'auto'}} 
                  variant='outlined' 
                  color={'primary'} 
                  onClick={() => {navigateToResourcesView(resumeB62Id)}} 
                  disabled={isDisabled || removeResumeInProgress} 
                  startIcon={<GridViewSharpIcon/>}
                >
                  View Tailored Resumes
                </Button>
              </span>
            </Tooltip>
          </Box>
        </>
        }

        <ConfirmDialogBox
          title="Please confirm"
          message="Please confirm you want to delete this resume!"
          open={removeResumeDialogOpen}
          primaryButton={{ label: 'Delete', onClick: () => {onRemoveResume(resumeB62Id);setRemoveResumeDialogOpen(false);} }}
          secondaryButton={{ label: 'Cancel', onClick: () => setRemoveResumeDialogOpen(false) }}
          fullWidth
          onClose={() => setRemoveResumeDialogOpen(false)}
        />


      </CardContent>
    </Card>
  )
}


export default ResumeCard;