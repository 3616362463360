import {
    ApiInstance, ApiInstanceNoAuth,
} from "./Instances";

import type {
    IUserPreferences,
    GoogleAuthResponse,
    GoogleLinkResponse,
    ApiResponse,
    ResumeFileApiResponse,
    ResumeListApiResponse,
    ResumeDetailsApiResponse,
    ResumeThumbnailApiResponse,
    JobApiResponse,
    UserSettingsResponse,
    ResumeDetails,
    ErrorResponse,
    BothOfComparisons,
    ValidationErrorDetails,
    IValidationError,
    UserBalanceResponse,
    CheckoutSessionResponse,
    CheckoutSessionStatusResponse,
} from "./interface";

import { storeAuthState } from '../Aws/User';
import { checkAndRefreshTokens } from '../Aws/Session';

// Helper to handle errors consistently
const handleError = (error: any) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return error.response;
    } else if (error.request) {
        // The request was made but no response was received
        return {
            status: 0,
            data: {
                message: 'No response received from server',
                error: error.message
            }
        };
    } else {
        // Something happened in setting up the request that triggered an Error
        return {
            status: 0,
            data: {
                message: 'Error setting up request',
                error: error.message
            }
        };
    }
};

// Define a simple type for axios responses
type ApiResponseType<T> = {
    data: T;
    status: number;
    statusText: string;
};

const debugLogs = process.env.REACT_APP_DEBUG_LOGS;


const maxWaitTimeForPolling = 5000;

const UpInstance = ApiInstance;

// Add token refresh interceptor
ApiInstance.interceptors.request.use(async (config) => {
  try {
    // Check and refresh tokens if needed
    const tokens = await checkAndRefreshTokens();
    if (tokens) {
      config.headers['Authorization'] = `Bearer ${tokens.accessToken}`;
    }
  } catch (error) {
    console.error('Error in token refresh:', error);
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const httpOnGetUserBalance = (userId: string): Promise<UserBalanceResponse> => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        UpInstance.get<UserBalanceResponse>(`/users/user/balance`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

interface UserBalanceUpgradeMembershipPayload {
    userId: string,
    membershipType: string,
}

export const httpOnUserBalanceUpgradeMembership = (payload: UserBalanceUpgradeMembershipPayload): Promise<UserBalanceResponse> => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': payload.userId
            }
        };
        const requestBody = {
        };
        UpInstance.post<UserBalanceResponse>(`/users/user/balance/activate-paid-mode`, requestBody, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

interface UserBalanceCreateCheckoutSessionPayload {
    userId: string,
    obexcoinsBucketSize: string,
    allowToChargeOffSession: boolean,
    frontendReturnUrl: string,
    isUpgradeToProOnSuccess: boolean,
}

export const httpOnUserBalanceCreateCheckoutSession = (payload: UserBalanceCreateCheckoutSessionPayload): Promise<CheckoutSessionResponse> => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': payload.userId
            }
        };
        const requestBody = {
            obexcoinsBucketSize: payload.obexcoinsBucketSize,
            allowToChargeOffSession: payload.allowToChargeOffSession,
            frontendReturnUrl: payload.frontendReturnUrl,
            isUpgradeToProOnSuccess: payload.isUpgradeToProOnSuccess,
        };
        UpInstance.post<CheckoutSessionResponse>(`/users/user/balance/recharge`, requestBody, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnUserBalanceGetCheckoutSessionStatus = (userId: string, checkoutSessionId: string): Promise<CheckoutSessionStatusResponse> => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            },
            params: {
                'checkout-session-id': checkoutSessionId
            }
        };
        UpInstance.get<CheckoutSessionStatusResponse>(`/users/user/balance/checkout-session-status`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

// data uploading api 


export const httpOnSaveFileData = (fileName: string, fileType: string, contentType: string, userId: string): Promise<ResumeFileApiResponse> => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload = {
            original_filename: fileName,
            mime_type: contentType,
        }

        ApiInstance.post<ResumeFileApiResponse>('/resume_file', payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetResumes = (userId: string): Promise<ResumeListApiResponse> => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            },
            params: {
                'include': [
                    'New',
                    'Processing',
                    'Ready',
                    'Failed',
                    'Edited',
                ].join(',')
            }
        };
        ApiInstance.get<ResumeListApiResponse>(`/resume/`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetResume = (resumeB62Id: string, userId: string): Promise<ResumeDetailsApiResponse> => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get<ResumeDetailsApiResponse>(`/resume/${resumeB62Id}`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnEditResume = (resumeB62Id: string, resumeData: ResumeDetails['resume_details'], userId: string): Promise<ResumeDetailsApiResponse> => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.put<ResumeDetailsApiResponse>(`/resume/${resumeB62Id}`, { 'resume': resumeData }, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetResumeThumbnail = (resumeB62Id: string, userId: string): Promise<ResumeThumbnailApiResponse> => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get<ResumeThumbnailApiResponse>(`/resume/${resumeB62Id}/thumb`, options)
            .then((res: ApiResponseType<ResumeThumbnailApiResponse>) => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}



export const httpOnSaveJobDescription = (jobDescription: string, jobTitleOnlyMode: boolean, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const key = jobTitleOnlyMode ? 'job_title_text' : 'job_description_text';
        const payload = {
            [key]: jobDescription
        }

        ApiInstance.post('/job', payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpPollJobDescriptionExtracted = (jobB62Id: string, userId: string, wait: number = 3000, updateCallback?: () => void) => {
    wait = Math.min(wait, maxWaitTimeForPolling);
    debugLogs && console.log('called httpPollJobDescriptionExtracted wait:', wait);
    return new Promise((resolve, reject) => {
        httpOnGetJobDescription(jobB62Id, userId)
            .then((res: any) => {
                // status: New, Processing, Ready or Failed
                if (res.data.status === "Failed") {
                    const message = "JobDescription processing Failed"
                    console.log(message)
                    reject({ message: message })
                } else if (["Ready", "Edited"].includes(res.data.status)) {
                    resolve(res)
                } else {
                    updateCallback && updateCallback();
                    setTimeout(() => {
                        httpPollJobDescriptionExtracted(jobB62Id, userId, wait * 2, updateCallback).then(resolve).catch(reject)
                    }, wait);
                }
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpPollResumeExtracted = (resumeB62Id: string, userId: string, wait: number = 3000, updateCallback?: () => void) => {
    wait = Math.min(wait, maxWaitTimeForPolling);
    debugLogs && console.log('called httpPollResumeExtracted wait:', wait)
    return new Promise((resolve, reject) => {
        httpOnGetResume(resumeB62Id, userId)
            .then((res: any) => {
                // status: New, Processing, Ready or Failed
                if (res.data.status === "Failed") {
                    reject(res)
                } else if (["Ready", "Edited"].includes(res.data.status)) {
                    resolve(res)
                } else {
                    updateCallback && updateCallback();
                    setTimeout(() => {
                        httpPollResumeExtracted(resumeB62Id, userId, wait * 2, updateCallback).then(resolve).catch(reject)
                    }, wait);
                }
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpPollGetResumeByResumeFileId = (resumeFileB62Id: string, userId: string, wait: number = 3000, max_try = 5) => {
    wait = Math.min(wait, maxWaitTimeForPolling);
    debugLogs && console.log('called httpPollGetResumeByResumeFileId wait:', wait)
    return new Promise((resolve, reject) => {
        if (max_try === 0) {
            debugLogs && console.log('httpPollGetResumeByResumeFileId: Maximum number of tries reached')
            reject({ message: "Maximum number of tries reached" });
        }
        httpOnGetResumeByResumeFileId(resumeFileB62Id, userId)
            .then((res: any) => {
                resolve(res);
            })
            .catch(error => {
                debugLogs && console.log('httpPollGetResumeByResumeFileId: Error', error)
                if (error.status && error.status === 404) {
                    setTimeout(() => {
                        httpPollGetResumeByResumeFileId(resumeFileB62Id, userId, wait * 2, max_try - 1).then(resolve).catch(reject);
                    }, wait);
                } else {
                    reject(error.response);
                }
            });
    });
}

export const httpOnGetJobDescription = (jobB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/job/${jobB62Id}`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnGetJobDescriptions = (userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/job/`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnGetResumeByResumeFileId = (resumeFileB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/resume_by_file/${resumeFileB62Id}`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnSubmitFeedback = (rating: number, feedback: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload = {
            feedback_rating: rating * 2, // backend expects rating * 2 to avoid decimals
            feedback_text: feedback,
        }

        ApiInstance.post(`/feedback`, payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnSubmitFeedbackLatex = (rating: number, feedback: string, userId: string,
    latexB62Id: string
) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload = {
            feedback_rating: rating * 2, // backend expects rating * 2 to avoid decimals
            feedback_text: feedback,
        }

        ApiInstance.post(`/feedback/${latexB62Id}`, payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnCreateTailored = (resumeB62Id: string, jobB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload = {
            resume_base62_id: resumeB62Id,
            job_base62_id: jobB62Id
        }

        ApiInstance.post('/tailored', payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetTailored = (tailoredB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/tailored/${tailoredB62Id}`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnGetTailoreds = (userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/tailored/`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export interface iDetailedStatusContextWithCallback { // this is a way to count and know which sub step we are currently to show progress bar percentage update
    status: string;
    statusChangedCounter: number;
    detailedStatusCallback: (status: string, statusChangedCounter: number) => void;
}


export const httpPollTailoredExtracted = (tailoredB62Id: string, userId: string, wait: number = 3000, detailedStatusContext: iDetailedStatusContextWithCallback) => {
    wait = Math.min(wait, maxWaitTimeForPolling);
    debugLogs && console.log('called httpPollTailoredExtracted wait:', wait)
    return new Promise((resolve, reject) => {
        httpOnGetTailored(tailoredB62Id, userId)
            .then((res: any) => {
                if (detailedStatusContext) {

                    const prevStatus = detailedStatusContext?.status || '';
                    const newStatus = res?.data?.processing_status || prevStatus; // hold the prev status if new status came empty.
                    if (newStatus === '' && !prevStatus) {
                        detailedStatusContext.statusChangedCounter = 0;
                    } else if (prevStatus !== newStatus) {
                        detailedStatusContext.statusChangedCounter += 1;
                    }
                    detailedStatusContext.detailedStatusCallback(newStatus, detailedStatusContext.statusChangedCounter)
                    detailedStatusContext.status = newStatus;
                }

                // status: New, Processing, Ready or Failed
                if (res.data.status === "Failed") {
                    const message = "Tailored cv generation Failed"
                    console.log(message)
                    reject({ message: message })
                } else if (["Ready", "Edited"].includes(res.data.status)) {
                    resolve(res)
                } else {
                    setTimeout(() => {
                        httpPollTailoredExtracted(tailoredB62Id, userId, wait * 2, detailedStatusContext).then(resolve).catch(reject)
                    }, wait);
                }
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnPutTailoredResume = (tailoredB62Id: string, tailoredResume: any, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload = {
            tailored_resume: tailoredResume['resume_details']
        }

        ApiInstance.put(`/tailored/${tailoredB62Id}`, payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnDeleteTailoredResume = (tailoredResumeB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        ApiInstance.delete(`/tailored/${tailoredResumeB62Id}`, options)
            .then(res => resolve(res.data))
            .catch(error => reject(error.response));
    });
};



export const httpOnCreateLatex = (tailoredB62Id: string, templateB62Id: string | null, preferences: IUserPreferences | null, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload: {
            tailored_resume_base62_id: string;
            template_base62_id?: string;
            override_preferences?: IUserPreferences | null;
        } = {
            tailored_resume_base62_id: tailoredB62Id,
            override_preferences: preferences
        };

        if (templateB62Id) {
            payload.template_base62_id = templateB62Id;
        }

        ApiInstance.post('/latex', payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetResumeFolder = (resume_base62_id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            },
            params: {
                resume_base62_id: resume_base62_id
            }
        };

        ApiInstance.get('/resume_folder', options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetLatex = (latexB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/latex/${latexB62Id}`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnCreatePdf = (latexB62Id: string, userId: string, renderInline: boolean = true) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };

        const payload = {
            latex_resume_base62_id: latexB62Id,
            render_inline: renderInline
        }

        ApiInstance.post('/pdf', payload, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}



export const httpOnDeleteResume = (resumeB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.delete(`/resume/${resumeB62Id}`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const httpOnGetTailoredComparisons = (tailoredB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get(`/tailored/${tailoredB62Id}/comparisons`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpPollTailoredComparisons = (tailoredB62Id: string, userId: string, wait: number = 3000, maxTry: number=12) => {
    wait = Math.min(wait, maxWaitTimeForPolling);
    debugLogs && console.log('called httpPollTailoredComparisons wait:', wait)
    return new Promise((resolve, reject) => {
        httpOnGetTailoredComparisons(tailoredB62Id, userId)
            .then((res: any) => {
                // status: New, Processing, Ready or Failed
                if (res.data.comparison_status === "Failed") {
                    const message = "Tailored Comparisons processing Failed"
                    console.log(message)
                    reject({ message: message })
                } else if (["Ready", "Edited"].includes(res.data.comparison_status)) {
                    resolve(res)
                } else {
                    setTimeout(() => {
                        httpPollTailoredComparisons(tailoredB62Id, userId, wait * 2, maxTry-1).then(resolve).catch(reject)
                    }, wait);
                }
            })
            .catch(error => {
                console.log('httpPollTailoredComparisons Error:', error)
                if (error?.status === 404 && maxTry > 0) { // sometimes record is not created yet so wait it to be created.
                    setTimeout(() => {
                        httpPollTailoredComparisons(tailoredB62Id, userId, wait * 2, maxTry-1).then(resolve).catch(reject)
                    }, wait);
                } else {
                    reject(error.response);
                }
            });
    });
}

export const httpOnGetTailoredResumeThumbnail = (tailoredB62Id: string, userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get<ResumeThumbnailApiResponse>(`/tailored/${tailoredB62Id}/thumb`, options)
            .then((res: ApiResponseType<ResumeThumbnailApiResponse>) => {
                resolve(res.data?.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnGetUser = (userId: string): Promise<UserSettingsResponse> => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.get<UserSettingsResponse>(`/user`, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}



export const httpOnPutUserPreferences = (userId: string, settings: IUserPreferences) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId
            }
        };
        ApiInstance.put<ApiResponse<IUserPreferences>>(`/user/settings`, settings, options)
            .then((res: any) => {
                resolve(res.data?.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}

export const httpOnPurgeAllMyData = (userId: string) => {
    return new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId,
            },
            params: {
                "purge": true
            }
        };
        ApiInstance.put<ApiResponse<any>>(`/user/purge`, {}, options)
            .then((res: any) => {
                resolve(res.data?.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}



export const httpOnJoinWaitlist = (email: string) => {
    return new Promise((resolve, reject) => {
        const options = {
        };
        ApiInstanceNoAuth.post(`/waitlist`, {email}, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}


export const parseFormValidationError = (error: any) => {
    let title = 'Unable to save your changes.' // generic error message
    let messages: any = []

    try {

        const e = error?.data;
        e.data = JSON.parse(e?.data);
    
        if (e?.statusCode === 400 && e?.message?.toLocaleLowerCase()?.includes('valid')) {// is validation error
            title = e?.message;
            messages = validationErrorsToMessages(e?.data);
        }
    
        return { title, 'messages': messages }

    } catch (err) {
        console.log('parseFormValidationError: ', err)
        return { title, 'messages': messages }
    }
  }

export function validationErrorsToMessages(errors: any[]) {
    let messages = []

    for (let i = 0; i < errors.length; i++) {

        let details = errors?.[i]?.loc; // example ['resume', 'experiance', 9, 'title', 'str']
        let path = []
        if (details?.length > 2) {
            // take details except first and last elements, first is allways 'resume', last is the data type
            path = details.slice(1, -1);

            // edge case when type is a tuple it looks like ['type...', 1], instead of type just ['type...']
            if (typeof details[details.length - 1] === "number" && path.length > 2) {
                path = path.slice(0, -1)
            }
        }
        
        const pathToDisplay = path.map((item: any) => { return typeof item === "number" ? 'item ' + (+item + 1) : item; }).join(' ');
        const message = `${errors?.[i]?.msg}: <span style="font-weight: bold">${pathToDisplay}</span>`;
        messages.push({message, path} as IValidationError);
    }

    return messages
}


export function formatErrorMessageHtml(errorMsg: string, e: any) {
    const supportEmail = 'support@resumefactory.ai';
    
    if (e?.data?.message) {
        return `<div>
            <p>${errorMsg}</p>
            <p>Error message: ${e?.data?.message}</p>
            Please retry or feel free to <a href="mailto:${supportEmail}">contact our support.</a>.
        </div>`
    }
    return `<div>
        <p>${errorMsg}</p>
        Please retry or feel free to <a href="mailto:${supportEmail}">contact our support.</a>.
    </div>`
}

const maxRetries = 3;
const initialRetryDelay = 1000; // 1 second

const shouldRetry = (error: any): boolean => {
    // Retry on network errors or specific status codes
    if (!error.response) return true; // Network error
    const status = error.response.status;
    return status === 429 || // Rate limit
           status === 503 || // Service unavailable
           status === 504;   // Gateway timeout
};

const retryRequest = async (
    requestFn: () => Promise<any>,
    retries: number = maxRetries,
    delay: number = initialRetryDelay
): Promise<any> => {
    try {
        return await requestFn();
    } catch (error: any) {
        if (retries > 0 && shouldRetry(error)) {
            if (debugLogs) {
                console.log(`Retrying request, attempts remaining: ${retries-1}, delay: ${delay}ms`);
            }
            await new Promise(resolve => setTimeout(resolve, delay));
            return retryRequest(requestFn, retries - 1, delay * 2);
        }
        throw error;
    }
};

export const httpOnGoogleAuth = (token: string): Promise<GoogleAuthResponse> => {
    return new Promise((resolve, reject) => {
        const payload = { googleToken: token };
        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        ApiInstanceNoAuth.post<GoogleAuthResponse>('/auth/google', payload, options)
            .then((res: any) => {
                // Store tokens consistently
                if (res.data?.data?.access_token) {
                    const accessToken = res.data.data.access_token;
                    const tokenWithoutBearer = accessToken.startsWith('Bearer ') ? accessToken.slice(7) : accessToken;
                    const idToken = res.data.data.id_token || tokenWithoutBearer; // Use id_token if available, fallback to access token
                    const userId = res.data.data.user.userId;
                    
                    // Store tokens using the same helper as email auth
                    storeAuthState(userId, tokenWithoutBearer, idToken, 'google');

                    // Log user info at login time
                    const user = res.data.data.user;
                    if (user) {
                        const userInfo = {
                            userId: user.userId,
                            email: user.email,
                            name: user.name,
                            isAdmin: user.isAdmin || false,
                            authProvider: 'google'
                        };
                        console.log('Login successful:', userInfo);
                        // Update the user object with the explicit isAdmin value and auth provider
                        res.data.data.user = { ...user, isAdmin: user.isAdmin || false, authProvider: 'google' };
                    }
                }
                resolve(res.data);
            })
            .catch(error => reject(handleError(error)));
    });
};

export const httpOnLinkGoogle = (token: string, userId: string): Promise<GoogleLinkResponse> => {
    const makeRequest = () => new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId,
                'Content-Type': 'application/json'
            }
        };
        
        const payload = { googleToken: token };
        
        if (debugLogs) {
            console.log('Linking Google account for user:', userId);
        }

        ApiInstance.post('/user/link/google', payload, options)
            .then(res => {
                if (debugLogs) {
                    console.log('Google account linking successful:', res.data);
                }
                resolve(res.data);
            })
            .catch(error => {
                if (debugLogs) {
                    console.error('Google account linking failed:', error.response);
                }
                reject(error);
            });
    });

    return retryRequest(makeRequest);
};

export const httpOnUnlinkGoogle = (userId: string): Promise<GoogleLinkResponse> => {
    const makeRequest = () => new Promise((resolve, reject) => {
        const options = {
            headers: {
                'user': userId,
                'Content-Type': 'application/json'
            }
        };
        
        if (debugLogs) {
            console.log('Unlinking Google account for user:', userId);
        }

        ApiInstance.post('/user/unlink/google', {}, options)
            .then(res => {
                if (debugLogs) {
                    console.log('Google account unlinking successful:', res.data);
                }
                resolve(res.data);
            })
            .catch(error => {
                if (debugLogs) {
                    console.error('Google account unlinking failed:', error.response);
                }
                reject(error);
            });
    });

    return retryRequest(makeRequest);
};