import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography, TextField, Alert, LinearProgress, Skeleton, Dialog, DialogTitle, DialogContent, DialogActions, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '../Components/Button';
import { selectUser } from '../Redux/User/Selectors';
import { awsOnChangePassword, awsOnInitialPasswordSetup, awsOnCompleteInitialPasswordSetup } from '../Services/Aws/User';
import { httpOnUnlinkGoogle, httpOnPurgeAllMyData } from '../Services/Http';
import { httpOnCheckAuthProvider } from '../Services/Http/Auth';
import { onUserSaveAction } from '../Redux/User/Actions';

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: 24,
}));

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  maxWidth: 600,
  margin: '0 auto',
}));

const StyledHeader = styled('div')(({ theme }) => ({
  marginBottom: 24,
}));

const Content = styled(Typography)(({ theme }) => ({
  marginBottom: 16,
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  marginBottom: 16,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: 16,
}));

const DangerButton = styled(Button)(({ theme }) => ({
  marginTop: 16,
  backgroundColor: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

const Profile: React.FC = () => {
  const theme = useTheme();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Password change states
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [changePasswordInProgress, setChangePasswordInProgress] = useState(false);
  
  // Auth provider states
  const [authProvider, setAuthProvider] = useState<'email' | 'google' | null>(null);
  const [authProviderLoading, setAuthProviderLoading] = useState(true);
  const [needsPasswordSetup, setNeedsPasswordSetup] = useState(false);

  // Password strength states
  const [passwordScore, setPasswordScore] = useState<number | null>(null);
  const [passwordScoreFeedback, setPasswordScoreFeedback] = useState<any>(null);

  // Dialog states
  const [showUnlinkDialog, setShowUnlinkDialog] = useState(false);
  const [purgeAllMyDataDialogOpen, setPurgeAllMyDataDialogOpen] = useState<boolean>(false);
  const [purgeAllMyDataError, setPurgeAllMyDataError] = useState('');
  const [purgeAllMyDataSuccess, setPurgeAllMyDataSuccess] = useState('');
  const [purgeAllMyDataInProgress, setPurgeAllMyDataInProgress] = useState<boolean>(false);
  const [unlinkInProgress, setUnlinkInProgress] = useState(false);

  // Add state for verification
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerification, setShowVerification] = useState(false);

  // Password strength check
  useEffect(() => {
    if (newPassword) {
      import('zxcvbn').then((zxcvbn) => {
        const result = checkPassword(zxcvbn.default, newPassword);
        setPasswordScore(result.score);
        setPasswordScoreFeedback(result.feedback);
      });
    } else {
      setPasswordScore(null);
    }
  }, [newPassword]);

  function checkPassword(zxcvbn: any, password: string, minLength = 8) {
    if (password.length < minLength) {
      return {
        score: 0,
        feedback: {
          warning: `Password must be at least ${minLength} characters long`,
          suggestions: ['Add more characters to meet the minimum length requirement']
        }
      };
    }
    return zxcvbn(password);
  }

  useEffect(() => {
    const checkAuthProvider = async () => {
      try {
        setAuthProviderLoading(true);
        const response = await httpOnCheckAuthProvider(user.email);
        setAuthProvider(response.data.auth_provider);
        // Set needsPasswordSetup if auth_provider is 'email' but no password is set
        setNeedsPasswordSetup(response.data.needs_password_setup || false);
      } catch (err) {
        console.error('Error checking auth provider:', err);
      } finally {
        setAuthProviderLoading(false);
      }
    };

    if (user.email) {
      checkAuthProvider();
    }
  }, [user.email]);

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      return;
    }

    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }

    if (passwordScore && passwordScore < 3) {
      setError('Please use a stronger password');
      return;
    }

    setChangePasswordInProgress(true);
    setError('');
    setSuccess('');

    try {
      await awsOnChangePassword(user.email, oldPassword, newPassword);
      setSuccess('Password changed successfully!');
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err: any) {
      setError(err.message || 'Failed to change password. Please try again.');
    } finally {
      setChangePasswordInProgress(false);
    }
  };

  const handleSetNewPassword = async () => {
    if (!showVerification) {
      if (newPassword !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }

      if (newPassword.length < 8) {
        setError('Password must be at least 8 characters long');
        return;
      }

      if (passwordScore && passwordScore < 3) {
        setError('Please use a stronger password');
        return;
      }

      setChangePasswordInProgress(true);
      setError('');
      setSuccess('');

      try {
        const result = await awsOnInitialPasswordSetup(user.email, newPassword);
        if (result.needsVerification) {
          setShowVerification(true);
          setSuccess(result.message);
        }
      } catch (err: any) {
        setError(err.message || 'Failed to initiate password setup. Please try again.');
      } finally {
        setChangePasswordInProgress(false);
      }
    } else {
      // Handle verification code submission
      setChangePasswordInProgress(true);
      setError('');
      setSuccess('');

      try {
        await awsOnCompleteInitialPasswordSetup(user.email, verificationCode, newPassword);
        setSuccess('Password set successfully!');
        setNeedsPasswordSetup(false);
        setNewPassword('');
        setConfirmPassword('');
        setVerificationCode('');
        setShowVerification(false);
      } catch (err: any) {
        setError(err.message || 'Failed to set password. Please try again.');
      } finally {
        setChangePasswordInProgress(false);
      }
    }
  };

  const handleUnlinkGoogle = async () => {
    try {
      setUnlinkInProgress(true);
      setError('');
      setSuccess('');
      console.log('Unlinking Google account...');
      
      // Unlink Google first
      await httpOnUnlinkGoogle(user.id);
      console.log('Successfully unlinked Google account');
      
      // Update Redux state
      dispatch(onUserSaveAction({
        ...user,
        authProvider: 'email'
      }));
      
      // Update local state
      setAuthProvider('email');
      setSuccess('Google account unlinked successfully. Redirecting to password reset...');
      
      // Navigate to forgot password with email pre-filled
      setTimeout(() => {
        navigate(`/forgot-password?email=${encodeURIComponent(user.email)}`);
      }, 1500);
      
    } catch (error: any) {
      console.error('Error in unlink process:', error);
      setError(error.message || 'Failed to unlink Google account');
    } finally {
      setUnlinkInProgress(false);
    }
  };

  const onConfirmPurgeAllMyData = async () => {
    setPurgeAllMyDataInProgress(true);
    setPurgeAllMyDataDialogOpen(false);
    setPurgeAllMyDataError('');
    setPurgeAllMyDataSuccess('');

    try {
      await httpOnPurgeAllMyData(user.userId);
      setPurgeAllMyDataSuccess('Your data purged successfully! Navigating back to home.');
      setTimeout(() => {
        navigate('/app');
      }, 2000);
    } catch (err) {
      setPurgeAllMyDataSuccess('Failed to purge the data. Please try again.');
      setPurgeAllMyDataError('');
    } finally {
      setPurgeAllMyDataInProgress(false);
    }
  };

  // Function to map password score to a color
  const getColorForStrength = (score: number) => {
    if (score <= 1) return theme.palette.error.main;
    if (score === 2) return theme.palette.warning.main;
    if (score === 3) return theme.palette.success.main;
    return theme.palette.success.main;
  };

  const getSectionColors = () => {
    const colors = ['grey', 'grey', 'grey', 'grey', 'grey'];
    if (passwordScore !== null) {
      for (let i = 0; i <= passwordScore; i++) {
        colors[i] = getColorForStrength(passwordScore);
      }
    }
    return colors;
  };

  const sectionColors = getSectionColors();

  return (
    <Root>
      <Container>
        {changePasswordInProgress && (
          <LinearProgress
            sx={{
              flexGrow: 1,
              borderTopRightRadius: '4px',
              borderTopLeftRadius: '4px'
            }}
          />
        )}
        <Box p={2}>
          <StyledHeader>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="h6">Profile</Typography>
              {user.isAdmin && (
                <Box
                  sx={{
                    backgroundColor: theme => theme.palette.primary.main,
                    color: theme => theme.palette.primary.contrastText,
                    borderRadius: '4px',
                    px: 1,
                    py: 0.5,
                    fontSize: '0.75rem',
                    fontWeight: 'medium',
                    textTransform: 'uppercase',
                    letterSpacing: '0.1em'
                  }}
                >
                  Admin
                </Box>
              )}
            </Box>
          </StyledHeader>

          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

          {authProviderLoading ? (
            <>
              <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" height={36} sx={{ width: '30%' }} />
            </>
          ) : authProvider === 'google' ? (
            <>
              <Content variant="body1">
                Your account is linked with Google. Unlinking will:
              </Content>
              <ul>
                <Typography component="li" variant="body1">Remove Google sign-in capability</Typography>
                <Typography component="li" variant="body1">Require setting up a password through the forgot password flow</Typography>
                <Typography component="li" variant="body1">Send a verification code to your email</Typography>
              </ul>
              <DangerButton
                label="Unlink Google Account"
                onClick={handleUnlinkGoogle}
                disabled={unlinkInProgress}
              />
              {unlinkInProgress && (
                <Box sx={{ mt: 2 }}>
                  <LinearProgress />
                  <Typography variant="body2" sx={{ mt: 1 }}>Unlinking Google account...</Typography>
                </Box>
              )}
            </>
          ) : needsPasswordSetup ? (
            <>
              <Content variant="body1">
                Set up a password for your account.
              </Content>
              <StyledInput
                label="New Password"
                type="password"
                variant="outlined"
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {passwordScore !== null && (
                <Box sx={{position: 'relative', minHeight: theme.spacing(8), mb: 2}}>
                  <Box p={1} sx={{border: '1px solid ' + theme.palette.secondary.main,
                    position: 'absolute',
                    background: 'white',
                    zIndex: 1000,
                    width: '100%',
                    borderRadius: theme.spacing(1)}}>
                    <Typography variant="body2" pb={1}>
                      Password Strength: {['Very Weak', 'Weak', 'Moderate', 'Strong', 'Very Strong'][passwordScore]}
                    </Typography>
                    {passwordScoreFeedback?.warning && (
                      <Typography variant="body2" color="warning.main" pb={1}>
                        Warning: {passwordScoreFeedback?.warning}
                      </Typography>
                    )}
                    {passwordScoreFeedback?.suggestions &&
                      passwordScoreFeedback?.suggestions?.length > 0 && (
                        <>
                          <Typography variant="body2">Suggestions:</Typography>
                          <ul>
                            {passwordScoreFeedback?.suggestions?.map((suggestion: any, index: any) => (
                              <Typography variant="body2" component="li" key={index}>
                                {suggestion}
                              </Typography>
                            ))}
                          </ul>
                        </>
                      )}
                    <Box display="flex" flexDirection="row" mt={1} sx={{justifyContent: 'space-between'}}>
                      {[0, 1, 2, 3, 4].map((index) => (
                        <LinearProgress
                          key={`progress-${index}`}
                          variant="determinate"
                          value={100}
                          sx={{ '& > span': {background: sectionColors[index]}}}
                          style={{ height: '5px', marginBottom: '2px', width: '15%' }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              )}
              <StyledInput
                label="Confirm New Password"
                type="password"
                variant="outlined"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {showVerification && (
                <StyledInput
                  label="Verification Code"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  helperText="Please check your email for the verification code"
                />
              )}
              <StyledButton
                label={showVerification ? "Verify and Set Password" : "Set Password"}
                onClick={handleSetNewPassword}
                disabled={changePasswordInProgress}
              />
            </>
          ) : authProvider === 'email' ? (
            <>
              <Content variant="body1">
                Change your password here.
              </Content>
              <StyledInput
                label="Old Password"
                type="password"
                variant="outlined"
                fullWidth
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <StyledInput
                label="New Password"
                type="password"
                variant="outlined"
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {passwordScore !== null && (
                <Box sx={{position: 'relative', minHeight: theme.spacing(8), mb: 2}}>
                  <Box p={1} sx={{border: '1px solid ' + theme.palette.secondary.main,
                    position: 'absolute',
                    background: 'white',
                    zIndex: 1000,
                    width: '100%',
                    borderRadius: theme.spacing(1)}}>
                    <Typography variant="body2" pb={1}>
                      Password Strength: {['Very Weak', 'Weak', 'Moderate', 'Strong', 'Very Strong'][passwordScore]}
                    </Typography>
                    {passwordScoreFeedback?.warning && (
                      <Typography variant="body2" color="warning.main" pb={1}>
                        Warning: {passwordScoreFeedback?.warning}
                      </Typography>
                    )}
                    {passwordScoreFeedback?.suggestions &&
                      passwordScoreFeedback?.suggestions?.length > 0 && (
                        <>
                          <Typography variant="body2">Suggestions:</Typography>
                          <ul>
                            {passwordScoreFeedback?.suggestions?.map((suggestion: any, index: any) => (
                              <Typography variant="body2" component="li" key={index}>
                                {suggestion}
                              </Typography>
                            ))}
                          </ul>
                        </>
                      )}
                    <Box display="flex" flexDirection="row" mt={1} sx={{justifyContent: 'space-between'}}>
                      {[0, 1, 2, 3, 4].map((index) => (
                        <LinearProgress
                          key={`progress-${index}`}
                          variant="determinate"
                          value={100}
                          sx={{ '& > span': {background: sectionColors[index]}}}
                          style={{ height: '5px', marginBottom: '2px', width: '15%' }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              )}
              <StyledInput
                label="Confirm New Password"
                type="password"
                variant="outlined"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <StyledButton
                label="Change Password"
                onClick={handleChangePassword}
                disabled={changePasswordInProgress}
              />
            </>
          ) : null}

          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Danger Zone
            </Typography>
            <DangerButton
              label="Purge All My Data"
              onClick={() => setPurgeAllMyDataDialogOpen(true)}
            />
          </Box>
        </Box>

        {/* Purge Data Dialog */}
        <Dialog
          open={purgeAllMyDataDialogOpen}
          onClose={() => setPurgeAllMyDataDialogOpen(false)}
          aria-labelledby="purge-dialog-title"
        >
          <DialogTitle id="purge-dialog-title">
            Purge All My Data
          </DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to purge all your data? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <StyledButton
              label="Cancel"
              onClick={() => setPurgeAllMyDataDialogOpen(false)}
            />
            <DangerButton
              label="Purge"
              onClick={onConfirmPurgeAllMyData}
            />
          </DialogActions>
        </Dialog>
      </Container>
    </Root>
  );
};

export default Profile;
