import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Alert,
  LinearProgress,
  Link,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { awsOnUserSignIn } from '../Services/Aws/User';
import { useDispatch } from 'react-redux';
import { onUserSaveAction } from '../Redux/User/Actions';
import Button from '../Components/Button';
import ImgLogo from '../Assets/logo-net-dark.png';

const Login: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [showGoogleOption, setShowGoogleOption] = useState(false);

  const handleLogin = async () => {
    setLoginInProgress(true);
    setError('');

    try {
      const result = await awsOnUserSignIn(email, password);
      
      // Check if password reset is required
      if (result.newPasswordRequired) {
        // Navigate to password reset page with email
        navigate('/new-password', { state: { email } });
        return;
      }

      // Normal login flow
      dispatch(onUserSaveAction(result));
      navigate('/app');
    } catch (err: any) {
      if (err.name === 'GoogleAuthRequired') {
        setError(err.message);
        setShowGoogleOption(true);
      } else {
        setError(err.message || 'Failed to login. Please try again.');
      }
    } finally {
      setLoginInProgress(false);
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password', { state: { email } });
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        background: `linear-gradient(135deg, ${theme.palette.primary.backgroundLight} 30%, ${theme.palette.secondary.backgroundLight} 90%)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.3)',
          pointerEvents: 'none',
        },
      }}
    >
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          borderRadius: 4,
          boxShadow: 5,
          overflow: 'hidden',
          width: '100%',
          maxWidth: { xs: '90%', sm: 500, md: 800 },
          m: 2,
          bgcolor: 'background.paper',
        }}
      >
        {/* Left side - Form */}
        <Box
          sx={{
            flex: { xs: '1', md: '1' },
            p: 4,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {loginInProgress && (
            <LinearProgress
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                borderTopLeftRadius: 4,
              }}
            />
          )}

          <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
            <img src={ImgLogo} alt="Logo" style={{ height: '40px' }} />
          </Box>

          <Typography variant="h5" sx={{ mb: 3, textAlign: 'center' }}>
            Welcome Back
          </Typography>

          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loginInProgress}
          />

          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            sx={{ mb: 1 }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loginInProgress}
          />

          <Link
            component="button"
            variant="body2"
            onClick={handleForgotPassword}
            sx={{
              alignSelf: 'flex-end',
              mb: 2,
              color: 'primary.main',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Forgot Password?
          </Link>

          <Button
            label="Login"
            onClick={handleLogin}
            disabled={loginInProgress}
            sx={{ mb: 2 }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Link
              component="button"
              variant="body2"
              onClick={() => navigate('/signup')}
              sx={{
                color: 'text.secondary',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Don't have an account? Sign Up
            </Link>
          </Box>

          {showGoogleOption && (
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              my: 2,
              '&::before, &::after': {
                content: '""',
                flex: 1,
                borderBottom: 1,
                borderColor: 'divider',
              },
            }}>
              <Typography variant="body2" sx={{ mx: 2, color: 'text.secondary' }}>
                Please use Google Sign-In for this account
              </Typography>
            </Box>
          )}
        </Box>

        {/* Right side - Image/Decoration */}
        <Box
          sx={{
            flex: { xs: '0', md: '1' },
            bgcolor: 'primary.light',
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
            p: 4,
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}40 30%, ${theme.palette.secondary.main}40 90%)`,
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: 'common.white',
              textAlign: 'center',
              position: 'relative',
              fontWeight: 'light',
              lineHeight: 1.5,
            }}
          >
            Transform Your Resume
            <br />
            for Every Opportunity
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Login; 