import React from "react";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, styled } from '@mui/material';
import { Box, Theme } from "@mui/material";
import useUser from "../Hooks/useUser";
import { useDispatch } from "react-redux";
import { onAlertShowAction } from "../Redux";
import { httpPollTailoredComparisons, formatErrorMessageHtml } from "../Services/Http";
import ComparisonScoresView, { ComparisonScoresSideBySideView } from "./ComparisonScoresView";
import { //BothOfComparisons, 
  Comparison } from "../Services/Http/interface";
import { Close, OpenInFull } from "@mui/icons-material";

const PREFIX = 'ComparisonScoresModal';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  box: `${PREFIX}-box`,
  dragNdrop: `${PREFIX}-dragNdrop`,
  modalRoot: `${PREFIX}-modalRoot`,
  textInput: `${PREFIX}-textInput`,
  iconContainer: `${PREFIX}-iconContainer`,
  error: `${PREFIX}-error`,
  button: `${PREFIX}-button`,
  browseFilesButton: `${PREFIX}-browseFilesButton`,
  title: `${PREFIX}-title`
};

const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    
  },

  [`& .${classes.container}`]: {
    position: "absolute",
    borderRadius: 7,
    width: "50%",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    margin: theme.spacing(2),
    backgroundColor: theme.colors.white,

  },

  [`& .${classes.box}`]: {
    alignItems: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.dragNdrop}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    borderRadius: 7,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.modalRoot}`]: {
    width: "100%",
  },

  [`& .${classes.textInput}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "center",
    marginTop: theme.spacing(3),
  },

  [`& .${classes.iconContainer}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },

  [`& .${classes.error}`]: {
    color: theme.colors.error,
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3)
  },

  [`& .${classes.browseFilesButton}`]: {
    marginTop: theme.spacing(2)
  },

  [`& .${classes.title}`]: {
    fontSize: theme.fontSize.lg,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
}));

interface Props {
  tailoredB62Id: string;
  onClose?: () => void;
  isDialogOpen: boolean;
  onChangeDialogOpen: (isDialogOpen: boolean) => void;
}

const ComparisonScores: React.FC<Props> = ({ tailoredB62Id, onClose, isDialogOpen, onChangeDialogOpen }) => {

  const { userId } = useUser();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string>(""); 

  const [tailoredComp, setTailoredComp] = React.useState<Comparison | undefined>(undefined);
  const [unTailoredComp, setUnTailoredComp] = React.useState<Comparison | undefined>(undefined);

  const dispatch = useDispatch();

  const displayError = React.useCallback((errorMessage: string, errorMessageHtml: string) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessageHtml);
  }, [dispatch]);

  React.useEffect(() => {

    async function fetchData() {
      setIsLoading(true);

      try {
        const res: any = await httpPollTailoredComparisons(tailoredB62Id, userId); // Removed non-null assertion

        setTailoredComp(res.data?.tailored_comparison);
        setUnTailoredComp(res.data?.untailored_comparison);

        setIsLoading(false);

      } catch (e) {
        const errorMsg = 'Unable to load resume comparison scores.';
        displayError(errorMsg, formatErrorMessageHtml(errorMsg, e))
        setIsLoading(false);
        return;
      }


      setErrorMessage('');
    }

    fetchData();

  }, [tailoredB62Id, userId, displayError]); 

  return (
    <Root className={classes.root}>
      <Box>
        <Box display={'flex'} flexDirection='row'>
          <Box width={'100%'}>
            <ComparisonScoresView tailoredComp={tailoredComp} unTailoredComp={unTailoredComp} isLoading={isLoading} onClose={onClose} />
          </Box>
        </Box>
      </Box>
      
      {errorMessage && <Alert severity="error">
            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
          </Alert>
      }

      <Dialog
          open={isDialogOpen}
          onClose={() => onChangeDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={'xl'}
      >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <ComparisonScoresSideBySideView tailoredComp={tailoredComp} unTailoredComp={unTailoredComp} isLoading={isLoading} onClose={onClose} />
          </DialogContent>
          <DialogActions>
            <Button
              variant={'contained'}
              onClick={() => onChangeDialogOpen(false)}
            >
              Close
            </Button>
          </DialogActions>
      </Dialog>
    </Root>
  );
};

export default ComparisonScores;
