import React, { useEffect, useState } from 'react';
import { Chip, TextField, ClickAwayListener, ChipOwnProps, Box, IconButton } from '@mui/material';
import { formDiffGreenColor } from './styles';
import { Close as IconClose, DeleteForever } from '@mui/icons-material';
import theme from '../../theme';

interface Props {
    label: string;
    onLabelChange?: (label: string) => void;
    onDelete?: () => void;
    existInDiffOrigin?: boolean;
    showDiff: boolean;
    // ...props;
}

const EditableChip: React.FC<ChipOwnProps & Props> = ({ label, onLabelChange, onDelete, existInDiffOrigin, showDiff, ...props }) => {
  
  const [isEditing, setIsEditing] = useState(!label);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [labelValue, setLabelValue] = useState(label);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event: any) => {
    setLabelValue(event.target.value);
  };

  const textFieldRef = React.useRef(null);


  const handleClickAway = (event: any) => {
    if (event.target.hasAttribute('EditableChipPreventclickaway')) {
      return;
    }
   
    setIsEditing(false);
    if (labelValue !== label && onLabelChange) {
        onLabelChange(labelValue);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setIsEditing(false);
      onLabelChange && onLabelChange(labelValue);
    }
  };

  const handleDeleteClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowDeleteConfirmation(true);
  };

  const handleCancelDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowDeleteConfirmation(false);
  };

  const handleConfirmDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDelete?.();
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    if (!isEditing && labelValue === '') {
      onDelete && onDelete();
    }

    // TODO: add autofocus functionality to the textfield.
    // if (isEditing) {
    //   textFieldRef.current && textFieldRef.current.childNodes[0].childNodes[0].focus()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ display: 'inline-block', ...(showDeleteConfirmation && { mr: 6 }) }}>
        {isEditing ? (
          <TextField
            value={labelValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoFocus={true}
            size="small"
            style={{ width: '100%', minWidth: '225px' }}
            inputProps={{ style: { padding: '4.5px 14px' }, EditableChipPreventclickaway: '', autofocus: true }}
            ref={textFieldRef}
            multiline={true}
          />
        ) : showDeleteConfirmation ? (
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Chip
              label={label}
              onDoubleClick={handleDoubleClick}
              {...props}
              sx={{ 
                m:'1px', 
                maxWidth: '70vw',
                textDecoration: "line-through",
                "& .MuiChip-label": {
                  color: theme.palette.error.main
                }
              }}
              style={{ backgroundColor: (!existInDiffOrigin && showDiff) ? formDiffGreenColor : 'unset' }}
            />
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              height: '0px',
              marginTop: theme.spacing(-8),
              marginLeft: theme.spacing(-0.5)
            }}>
              <IconButton size="small" onClick={handleCancelDelete}>
                <IconClose fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={handleConfirmDelete} color="error">
                <DeleteForever fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Chip
            label={label}
            onDelete={handleDeleteClick}
            onDoubleClick={handleDoubleClick}
            {...props}
            sx={{ m:'1px', maxWidth: '70vw' }}
            style={{ backgroundColor: (!existInDiffOrigin && showDiff) ? formDiffGreenColor : 'unset' }}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default EditableChip;
