import { 
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box, 
  Button, 
  CircularProgress, 
  Divider, 
  IconButton, 
  LinearProgress, 
  Skeleton, 
  Tooltip, 
  Typography 
} from "@mui/material";
import React, { useRef, useState, useCallback } from "react";
import { DeleteForever, EditNote, ExpandMore, Folder, Cancel } from "@mui/icons-material";
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp';
import { useNavigate, useParams } from "react-router-dom";
import { 
  httpOnCreateLatex, 
  httpOnGetJobDescription, 
  httpOnDeleteResume, 
  httpOnGetTailored, 
  httpOnPutTailoredResume, 
  httpOnGetResume, 
  parseFormValidationError,
  formatErrorMessageHtml
} from "../Services/Http";
import useUser from "../Hooks/useUser";
import { useDispatch } from "react-redux";
import { onAlertShowAction } from "../Redux/Alert/Actions";
import ResumeForm, { IResumeFormRef } from "../Components/ResumeForm/ResumeForm";
import { formatUpdatedAt } from "../Services/Util/dateFormat";
import ResumeTitle from "../Components/ResumeTitle";
import ConfirmDialogBox from "../Components/ConfirmDialogBox";
import UserPreferences from "../Components/UserPreferences";
import { IUserPreferences, IValidationError } from "../Services/Http/interface";
import ResumeFormError from "../Components/ResumeForm/ResumeFormError";
import { stickySubheaderStyles } from "../Components/ResumeForm/styles";
import FeedbackSidePopup from "../Components/FeedbackSidePopup";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";

const debugLogs = process.env.REACT_APP_DEBUG_LOGS;



// src/pages/TailoredResumeEdit.js
export default function TailoredResumeEdit() {

  const [fileName, setFileName] = useState<string>("");
  const [resumeUpdatedAt, setResumeUpdatedAt] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<string>("");
  const [jobCompany, setJobCompany] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorMessages, setErrorMessages] = useState<IValidationError[]>([]);
  const [tailoredResume, setTailoredResume] = useState<any | null>(null);
  const [resume, setResume] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // eslint-disable-next-line 
  const [latexB62Id, setLatexB62Id] = React.useState<string>("");
  // eslint-disable-next-line 
  const [resumeB62Id, setResumeB62Id] = React.useState<string>("");
  // eslint-disable-next-line 
  const [generationProgress, setGenerationProgress] = useState<number>(0);
  const [generateInProgress, setGenerateInProgress] = useState<boolean>(false);
  // eslint-disable-next-line 
  const [generateCompleted, setGenerateCompleted] = useState<boolean>(false);
  //const [generationProgressMessage, setGenerationProgressMessage] = useState<string>("");

  const [removeResumeDialogOpen, setRemoveResumeDialogOpen] = useState<boolean>(false);

  const [preferences, setPreferences] = useState<IUserPreferences | null>(null);

  const [removeResumeInProgress, setRemoveResumeInProgress] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams<{ tailoredB62Id: string; jobB62Id: string; resumeB62Id: string }>();
  const { userId } = useUser();
  const dispatch = useDispatch();
  const formRef = useRef<IResumeFormRef>(null);
  const submitButtonText = 'Save and generate PDF file';
  const theme = useTheme();

  const navigateToResourcesView = (resumeB62Id: string)  =>  navigate(`/app/${params.resumeB62Id}/folder`);


  // Fetch Tailored Resume, Resume, and Job Description data
  React.useEffect(() => {
    setIsLoading(true);
    httpOnGetTailored(params.tailoredB62Id!, userId)
      .then((res: any) => {
        const tailored = res.data;
        setTailoredResume(tailored);
        if (debugLogs) console.log("tailored", tailored);

        setTimeout(() => {
          setIsLoading(false);
        }, 3000) // give time for the form to render, and prevent submit while it is still rendering
      })
      .catch((e) => {
        setIsLoading(false);
        const errorMsg = 'We were unable to load your tailored resume data. This could be due to a temporary connection issue. Please refresh the page and try again. If the problem persists, contact our support team for assistance.';
        displayError(errorMsg, formatErrorMessageHtml(errorMsg, e));
      });

    setErrorMessage('');
    setErrorMessages([]);

    // Fetch Resume Data
    httpOnGetResume(params.resumeB62Id!, userId)
      .then((res: any) => {
        const resume = res.data;
        setFileName(resume.file_name);
        setResumeUpdatedAt(formatUpdatedAt(resume.created_at));
        setResume(resume);
      })
      .catch((e: any) => {
        // Optionally handle the error
      });

    // Fetch Job Description Data
    httpOnGetJobDescription(params.jobB62Id!, userId)
      .then((res: any) => {
        const jb = res.data;
        const title = jb?.analysis?.job_title?.title || jb?.job_title_details?.title || jb?.job_details?.titles?.[0] || "";
        setJobTitle(title);
        const company = jb?.job_details?.company || jb?.job_title_details?.industry || "";
        setJobCompany(company);
      })
      .catch((e: any) => {
        // Optionally handle the error
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.tailoredB62Id, params.jobB62Id, params.resumeB62Id, userId]);

  // /app/${params.resumeB62Id}/job/${jobB62Id}/tailored-resume/${tailoredB62Id}

  const displayValidationError = useCallback((errorMessage: string, messages?: IValidationError[]) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessage);
    setErrorMessages(messages || []);
    setGenerateInProgress(false);
  }, [dispatch]);

  const displayError = (errorMessage: string, errorMessageHtml: string) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessageHtml);
    setErrorMessages([]);
    setGenerateInProgress(false);
  }
  

  const steps = {
    started: {
      progress: 0,
      message: 'Processing'
    },
    latex: {
      progress: 65,
      message: 'Structuring Resume Document'
    },
    pdf: {
      progress: 80,
      message: 'Generating Resume PDF Resume'
    },
    done: {
      progress: 100,
      message: 'Done, Please see Resume PDF Resume in newly opened tab'
    }
  }

  const setProgressStep = (step: any) => {
    //setGenerationProgress(step.progress);
    //setGenerationProgressMessage(step.message);
  }

  const saveAndGeneratePdf = async (tailoredResumeDetails: any) => {

    const updatedTailoredResume = {
      ...tailoredResume,
      resume_details: tailoredResumeDetails
    }

    setTailoredResume(updatedTailoredResume);

    setProgressStep(steps.started);

    setErrorMessage('');
    setErrorMessages([]);

    setGenerateCompleted(false);
    setGenerateInProgress(true);
    setGenerationProgress(0);

    
    setProgressStep(steps.latex);

    const tailoredB62Id = params.tailoredB62Id!;

    try {
      debugLogs && console.log('httpOnPutTailoredResume')
      // eslint-disable-next-line 
      const res: any = await httpOnPutTailoredResume(tailoredB62Id, updatedTailoredResume, userId);
      
    } catch (error: any) {

      const { title, messages } = parseFormValidationError(error);
      if (messages.length > 0) {
        displayValidationError(title, messages)
      } else {
        displayError(title, formatErrorMessageHtml(title, error))
      }
      return;
    }

    

    const templateB62Id = null; // use default template, override if templateB62Id is provided
    let latexB62Id: string = '';
    try {
      debugLogs && console.log('httpOnCreateLatex')

      const res: any = await httpOnCreateLatex(tailoredB62Id, templateB62Id, preferences, userId);

      latexB62Id = res.data.base62_id;

      setLatexB62Id(latexB62Id);
    } catch (e) {
      const errorMsg = 'We were unable to structuring the resume document. Please try again, and if the issue persists, contact our support team for assistance.';
      displayError(errorMsg, formatErrorMessageHtml(errorMsg, e));
      return;
    }


    setTimeout(() => {
      setGenerateInProgress(false);
      setGenerateCompleted(true);

      navigate(`/app/${params.resumeB62Id}/job/${params.jobB62Id}/tailored-resume/${tailoredB62Id}/latex/${latexB62Id}`);
    }, 1500);

  }

  const removeResume = async (resumeB62Id: string) => {
    setRemoveResumeInProgress(true);
    try {
      await httpOnDeleteResume(resumeB62Id, userId)
    } catch (e) {
      const errorMsg = 'We were unable to delete your resume at this time. This could be due to a temporary system issue. Please try again in a few moments, and if the problem persists, contact our support team for assistance.';
      displayError(errorMsg, formatErrorMessageHtml(errorMsg, e));

      setRemoveResumeInProgress(false);
      return;
    }
    setRemoveResumeInProgress(false);
    navigate(`/app`)
  }

  const editResume = (resumeB62Id: string)  =>  navigate(`/app/${params.resumeB62Id}/edit`);

  return (
    <Box pt={3}>
      <Box sx={stickySubheaderStyles}>
        <Box pb={3} sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', gap: 2, flexGrow: 1 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center' }}>
              <Box sx={{ 
                position: 'relative',
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: theme.spacing(1),
                padding: theme.spacing(1.5),
                boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.08)'
              }}>
                <ResumeTitle fileName={fileName} resumeUpdatedAt={resumeUpdatedAt} />
                <Box sx={{'display': 'flex'}}>
                  <Tooltip title={'Edit original resume text.'} placement="top" arrow>
                    <span>
                      <IconButton color={'primary'}
                        onClick={() => editResume(resumeB62Id)} disabled={generateInProgress || removeResumeInProgress || !params.resumeB62Id}>
                        <EditNote/>
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
              <Box sx={{ 
                position: 'relative',
                display: 'flex', 
                flexDirection: 'column',
                gap: 1,
                background: alpha(theme.palette.secondary.main, 0.9),
                backdropFilter: 'blur(8px)',
                border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
                borderRadius: theme.spacing(1),
                padding: theme.spacing(1.5),
                boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.15)}`,
                minWidth: '300px',
                '&:hover': {
                  background: alpha(theme.palette.secondary.main, 0.95),
                  boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.2)}`,
                  transition: 'all 0.3s ease-in-out'
                }
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', overflow: 'hidden' }}>
                  <Tooltip title={jobTitle} placement="top" arrow>
                    <Typography variant="subtitle1" sx={{ 
                      color: 'white',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '70%'
                    }}>{jobTitle}</Typography>
                  </Tooltip>
                  {!jobTitle && <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px', bgcolor: 'rgba(255, 255, 255, 0.1)' }} />}
                  <Typography sx={{ mx: 1, color: 'white', flexShrink: 0 }}>&#x2022;</Typography>
                  <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', flexShrink: 0 }}>{jobCompany}</Typography>
                  {!jobCompany && <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px', bgcolor: 'rgba(255, 255, 255, 0.1)' }} />}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                    Tailored at <Box component="span" sx={{ fontWeight: 'bold', color: 'white' }}>{formatUpdatedAt(tailoredResume?.created_at)}</Box>
                  </Typography>
                  <Tooltip title={'View previously added job descriptions and resumes'} placement="top" arrow>
                    <span>
                      <IconButton 
                        color={'primary'} 
                        onClick={() => navigateToResourcesView(params.resumeB62Id!)} 
                        disabled={generateInProgress || removeResumeInProgress}
                        sx={{ color: 'white' }}
                      >
                        <GridViewSharpIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
                <Tooltip title={'Delete the Tailored Resume'} placement="top" arrow>
                  <IconButton 
                    onClick={() => setRemoveResumeDialogOpen(true)} 
                    disabled={generateInProgress || removeResumeInProgress}
                    size="small"
                    sx={{ 
                      position: 'absolute',
                      top: -12,
                      right: -12,
                      backgroundColor: 'white',
                      border: '2px solid',
                      borderColor: theme.palette.grey[300],
                      padding: '4px',
                      '&:hover': {
                        backgroundColor: theme.palette.error.main,
                        borderColor: theme.palette.error.main,
                        '& svg': {
                          color: 'white'
                        }
                      },
                      '& svg': {
                        color: theme.palette.grey[700],
                        fontSize: '1.2rem'
                      }
                    }}
                  >
                    <Cancel />
                  </IconButton>
                </Tooltip>
                {removeResumeInProgress && <CircularProgress sx={{
                  width: '20px !important', height: '20px !important'
                }}/>}
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, marginLeft: 'auto' }}>
              <Box>
                <UserPreferences isFormViewMode={true} onChange={setPreferences} />
              </Box>

              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                disabled={isLoading || generateInProgress || removeResumeInProgress || generateInProgress}
                onClick={() => formRef.current?.submit()}
              >
                {submitButtonText}
              </Button>
            </Box>
          </Box>
        </Box>

        { (isLoading || generateInProgress) && <Box pt={1} pb={2}>
          <LinearProgress sx={{
            height: '4px',
            borderRadius: '2px'
          }}/>
        </Box>}

        <ResumeFormError errorMessage={errorMessage}
            errorMessages={errorMessages}
            scrollToElementByPath={formRef?.current?.scrollToElementByPath}/>
      </Box>

      <ResumeForm
        data={tailoredResume && tailoredResume['resume_details']}
        diffOriginData={resume && resume['resume_details']}
        onDataChange={(data) => {saveAndGeneratePdf({...tailoredResume['resume_details'], ...data});}}
        disabled={ isLoading || generateInProgress || removeResumeInProgress}
        ref={formRef}
        hideSubmitButton={true}
        submitText={submitButtonText}
        submitInProgress={generateInProgress}
        showDiff={true}
        showPersonalStatementsSection={false}
      />


      {/* <Box pb={3}>
        <Button
          variant="contained"
          disabled={saveInProgress}
          onClick={() => handleSave()}
          >
          Save
        </Button>
      </Box> */}

      <FeedbackSidePopup></FeedbackSidePopup>

      <ConfirmDialogBox
        title="Please confirm"
        message="Please confirm you want to delete this resume!"
        open={removeResumeDialogOpen}
        primaryButton={{ label: 'Delete', onClick: () => {removeResume(params.resumeB62Id!);setRemoveResumeDialogOpen(false);} }}
        secondaryButton={{ label: 'Cancel', onClick: () => setRemoveResumeDialogOpen(false) }}
        fullWidth
        onClose={() => setRemoveResumeDialogOpen(false)}
      />

      <Box pt={1} pb={6}>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Changes made
          </AccordionSummary>
          <AccordionDetails>
            
            <Box>
              {tailoredResume?.resume_details?.changes_made?.map((item: any, index: number) => (
                <Box key={`change-${index}`} p={1}>
                  <Typography sx={{fontWeight: 'bold'}}>{item?.reasoning}</Typography>
                  <Typography>{item?.description}</Typography>
                  <Divider sx={{ pt: 1 }} />
                </Box>
              ))}
            </Box>

          </AccordionDetails>
        </Accordion>
      </Box>

    </Box>
  );
}



