import React from "react";
import { Box, Skeleton, Typography } from '@mui/material';
import theme from '../theme';
import { blue } from '@mui/material/colors';

interface Props {
    fileName: string;
    resumeUpdatedAt: string;
}

const ResumeTitle: React.FC<Props> = ({fileName, resumeUpdatedAt}) => {
  return (
    <Box 
      sx={{
        background: `linear-gradient(135deg, ${blue[500]}, ${theme.palette.primary.light})`,
        py: 1,
        width: '100%',
        borderRadius: theme.spacing(1.5),
        boxShadow: `0 4px 12px rgba(0,0,0,0.15), 0 2px 4px rgba(0,0,0,0.12)`,
        px: 2,
        position: 'relative'
      }}
    >
      <Typography 
        variant="subtitle1" 
        sx={{ 
          fontWeight: 500,
          color: theme.palette.common.white,
          mb: 0.5,
          fontSize: '0.875rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {fileName || <Skeleton variant="text" width={100} />}
      </Typography>
      <Typography 
        variant="body2" 
        sx={{ 
          color: theme.palette.common.white,
          fontSize: '0.75rem',
          opacity: 0.85
        }}
      >
        {resumeUpdatedAt || <Skeleton variant="text" width={100} />}
      </Typography>
    </Box>
  );
}

export default ResumeTitle;