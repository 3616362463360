import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography, Theme, TextField, styled, Chip, Button } from "@mui/material";
import {
  DeleteForever,
  Add as IconAdd,
  Undo,
  Close as CloseIcon,
  Check as CheckIcon
} from '@mui/icons-material';
import PrimaryTextInput from "../PrimaryTextInput";
import { Controller, useFieldArray} from "react-hook-form";
import TextDiffComponent from "./TextDiffComponent";
import DateOrDates from "./DateOrDates";
import theme from "../../theme";
import { borderedSectionStyles, formDiffRedColor } from "./styles";
import { FormRemovedDetail } from "./FormRemovedDetail";
import { getByPath, getId } from "./utils";
import ExperienceClientProjects from "./ResumeFormExperienceClientProjects";
import { alpha } from '@mui/material/styles';

const PREFIX = 'ResumeForm';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
  
    display: "flex",
    flexDirection: 'column',
    alignItems: "left",
    justifyContent: "left",
  },

}));


// "experience": {
//   "description": "Candidate education credentials",
//   "items": {
//       "$ref": "#/$defs/Experience"
//   },
//   "title": "Experience",
//   "type": "array"
// },

// "Experience": {
//   "properties": {
//       "company": {
//           "title": "Company",
//           "type": "string"
//       },
//       "location": {
//           "title": "Location",
//           "type": "string"
//       },
//       "title": {
//           "title": "Title",
//           "type": "string"
//       },
//       "dates": {
//           "title": "Dates",
//           "type": "string"
//       },
//       "details": {
//           "items": {
//               "type": "string"
//           },
//           "title": "Details",
//           "type": "array"
//       },
//       "dek": {
//           "anyOf": [
//               {
//                   "type": "string"
//               },
//               {
//                   "type": "null"
//               }
//           ],
//           "default": null,
//           "title": "Dek"
//       }
//   },
//   "required": [
//       "company",
//       "location",
//       "title",
//       "dates",
//       "details"
//   ],
//   "title": "Experience",
//   "type": "object"
// }

// {
//   "dek": null,
//   "dates": "Oct 2016 – Present",
//   "title": "Software Engineer",
//   "company": "Google",
//   "details": [
//     "TensorFlow: Worked on APIs and performance for training models on Tensor Processing Units (TPU).",
//     "Apache Beam: Worked on batch and streaming data-parallel processing pipelines."
//   ],
//   "location": "Mountain View, CA"
// },



interface ResumeFormExperienceProps {
  control: any,
  fieldPath: string, // can have value of "experiance" or "experiance_groups.Volunteer Experience" or "experiance_groups.[possibly other...]"
  _required?: boolean,
  diffOriginData?: any,
  showDiff: boolean,
  title: string,
  isOtherExperiance?: boolean // determines if Company vs Organization property should be used.
}

interface DetailItemProps {
  val: string;
  i: number;
  values: string[];
  onChangeDetails: (values: string[]) => void;
  isDeleting: boolean;
  setDeletingIndex: (index: number | null) => void;
  showDiff: boolean;
  diffOriginData: any;
  experienceIndex: number;
  fieldPath: string;
}

const DetailItem: React.FC<DetailItemProps> = ({ 
  val, 
  i, 
  values, 
  onChangeDetails,
  isDeleting,
  setDeletingIndex,
  showDiff = false,
  diffOriginData,
  experienceIndex,
  fieldPath
}) => {
  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: '1' }}>
          <TextField
            value={val}
            onChange={(newVal) => onChangeDetails([...(values[i] = newVal.target.value, values)])}
            size="small"
            style={{ width: '100%' }}
            multiline={true}
            sx={{
              mb: theme.spacing(0.5),
              '& .MuiInputBase-input': {
                textDecoration: isDeleting ? 'line-through !important' : 'none',
                color: isDeleting ? `${theme.palette.error.main} !important` : 'inherit',
              },
              '& .MuiOutlinedInput-root': {
                borderColor: isDeleting ? `${theme.palette.error.main} !important` : 'inherit',
                '&.Mui-focused fieldset': {
                  borderColor: isDeleting ? `${theme.palette.error.main} !important` : 'inherit',
                },
              }
            }}
          />

          {showDiff && fieldPath && typeof experienceIndex === 'number' && (
            <TextDiffComponent 
              originalText={getByPath(diffOriginData, fieldPath)?.[experienceIndex]?.details?.[i] || ''}
              updatedText={val || ''}
              onTextUpdated={(newVal) => onChangeDetails([...(values[i] = newVal, values)])}
            />
          )}
        </Box>

        <Box>
          {!isDeleting ? (
            <IconButton 
              onClick={() => setDeletingIndex(i)}
              size="small"
            >
              <DeleteForever />
            </IconButton>
          ) : (
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <IconButton 
                size="small"
                onClick={() => {
                  onChangeDetails([...values.slice(0, i), ...values.slice(i + 1)]);
                  setDeletingIndex(null);
                }}
                sx={{ color: theme.palette.error.main }}
              >
                <DeleteForever />
              </IconButton>
              <IconButton 
                size="small"
                onClick={() => setDeletingIndex(null)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const ResumeFormExperience: React.FC<ResumeFormExperienceProps> = ({ control, fieldPath, _required, diffOriginData, showDiff, title, isOtherExperiance }) => {

  const experience = getByPath(control._formValues, fieldPath);

  const [state, setState] = useState<number>(0); // solely to trigger re-render for to have updated awards variable in the component

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: fieldPath, // unique name for your Field Array
  });

  const path = fieldPath;

  const [deletingDetailIndex, setDeletingDetailIndex] = useState<{experienceIndex: number, detailIndex: number} | null>(null);
  const [deletingExperienceIndex, setDeletingExperienceIndex] = useState<number | null>(null);

  return <Root className={classes.root}>
    <Typography>{title}
      <IconButton onClick={() => {append({})}}
        disabled={experience?.length > 0 && !(experience[experience.length -1]?.title || experience[experience.length -1]?.company)}>
        <IconAdd/>
      </IconButton>
    </Typography>

    {fields.map((field: any, index: number) => (
      <Box key={field.id} sx={{display: 'flex', position: 'relative', pb: 1}}>

        <Box sx={{position: 'absolute', left: theme.spacing(-1), top: theme.spacing(-1)}}>
          <Chip label={index + 1} size="small" sx={{color: theme.palette.text.secondary}} />
        </Box>

        <Box sx={{
          flexGrow: 1, 
          ...borderedSectionStyles,
          ...(deletingExperienceIndex === index && {
            borderColor: `${theme.palette.error.main} !important`,
            '& .MuiInputBase-input': {
              color: `${theme.palette.error.main} !important`,
              textDecoration: 'line-through',
            }
          })
        }}>

          <Controller
            name={`${path}.${index}.title`}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Box>
                <PrimaryTextInput
                  id={getId(name)}
                  name="title"
                  label="Title"
                  onChange={(event:any) => {onChange(event); setState(state + 1)}}
                  value={ value || '' }
                  required={_required && true}
                  multiline={true}
                />

              </Box>
            )}
          />

          { isOtherExperiance ?

              <Controller
              name={`${path}.${index}.organization`}
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Box>
                  <PrimaryTextInput
                    id={getId(name)}
                    name="organization"
                    label="Organization"
                    onChange={(event:any) => {onChange(event); setState(state + 1)}}
                    value={ value || '' }
                    required={_required && true}
                    multiline={true}
                  />

                </Box>
              )}
              />

              :

              <Controller
                name={`${path}.${index}.company`}
                control={control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Box>
                    <PrimaryTextInput
                      id={getId(name)}
                      name="company"
                      label="Company"
                      onChange={(event:any) => {onChange(event); setState(state + 1)}}
                      value={ value || '' }
                      required={_required && true}
                      multiline={true}
                      defaultValue={ getByPath(diffOriginData, path)?.[index]?.company || ''}
                    />

                  </Box>
                )}
              />
          }

          <Controller
            name={`${path}.${index}.location`}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Box>
                <PrimaryTextInput
                  id={getId(name)}
                  name="location"
                  label="Location"
                  onChange={onChange}
                  value={ value || '' }
                  required={_required && false}
                  multiline={true}
                  defaultValue={ getByPath(diffOriginData, path)?.[index]?.location || '' }
                />

              </Box>
            )}
          />

          <DateOrDates
              formPath={`${path}.${index}.date_or_dates`}
              // dateIsRange={control._formValues?.experience?.[index]?.date_is_range}
              dateIsRange={true}
              control={control}
              required={_required && false}
          />

          <Controller
            name={`${path}.${index}.details`}
            control={control}
            defaultValue={[]}
            render={({ field: { onChange: onChangeDetails, onBlur, value: values, name } }) => (
              <>
                <Typography>Experience Details
                  <IconButton 
                    onClick={() => {onChangeDetails([...values, ''])}}
                    disabled={values.length > 0 && values[values.length - 1] === ''}
                  >
                    <IconAdd/>
                  </IconButton>
                </Typography>

                {values.map((val: any, i: number) => (
                  <DetailItem 
                    key={`${field.id}-detail-${val || ''}-${i}`}
                    val={val}
                    i={i}
                    values={values}
                    onChangeDetails={onChangeDetails}
                    isDeleting={deletingDetailIndex?.experienceIndex === index && deletingDetailIndex?.detailIndex === i}
                    setDeletingIndex={(detailIndex) => 
                      setDeletingDetailIndex(detailIndex === null ? null : {
                        experienceIndex: index,
                        detailIndex: detailIndex
                      })
                    }
                    showDiff={showDiff}
                    diffOriginData={diffOriginData}
                    experienceIndex={index}
                    fieldPath={path}
                  />
                ))}
              </>
            )}
          />
        </Box>
      </Box>
    ))}
  </Root>;
};

export default ResumeFormExperience;