import {
    USER_SAVE,
    USER_REMOVE,
    USER_SAVE_CREDENTIALS,
    USER_ACTIVE_IMPERSONATION,
    USER_DEACTIVE_IMPERSONATION,
    USER_SIGN_OUT,
} from './Types';

import UserPool from '../../Configs/UserPool'
import { PATH_TO_ROOT } from '../../Router/Paths';
import { push } from '@lagunovsky/redux-react-router';
import { awsOnUserSignOut } from '../../Services/Aws/User';

export const onSignOutAction = (onComplete?: () => void): any => {
    return async (dispatch: any) => {
        try {
            // First clear all auth state (includes Cognito signout, storage cleanup, and Google cleanup)
            await awsOnUserSignOut();
            
            // Then clear all Redux state
            dispatch({ type: USER_SIGN_OUT });
            dispatch(onUserRemoveAction());
            dispatch(onUserImpersonateDeactiveAction());  // Clear any impersonation state
            dispatch(onUserSaveCredentials({ provider: '', roleArn: '', region: '' }));  // Clear credentials
            
            // Finally navigate
            dispatch(push(PATH_TO_ROOT));
            if (onComplete) {
                onComplete();
            }
        } catch (error) {
            console.error('Error during sign out:', error);
            // Still try to clean up Redux state and navigate on error
            dispatch({ type: USER_SIGN_OUT });
            dispatch(onUserRemoveAction());
            dispatch(onUserImpersonateDeactiveAction());  // Clear any impersonation state
            dispatch(onUserSaveCredentials({ provider: '', roleArn: '', region: '' }));  // Clear credentials
            dispatch(push(PATH_TO_ROOT));
            if (onComplete) {
                onComplete();
            }
        }
    };
};

export const onUserSaveAction = (payload: { 
    id: string, 
    name: string, 
    email: string, 
    image: string, 
    role: string, 
    accessToken: string,
    isAdmin: boolean 
}): any => {
    return (dispatch: any) => {
        dispatch({ type: USER_SAVE, payload: payload });
    }
}

export const onUserRemoveAction = (): any => {
    return (dispatch: any) => {
        dispatch({ type: USER_REMOVE });
    }
}

export const onUserImpersonateActiveAction = (impersonatedId: string): any => {
    return (dispacth: any) => {
        dispacth({ type: USER_ACTIVE_IMPERSONATION, payload: impersonatedId });
    }
}

export const onUserImpersonateDeactiveAction = () => {
    return (dispacth: any): any => {
        dispacth({ type: USER_DEACTIVE_IMPERSONATION });
    }
}

export const onUserSaveCredentials = (payload: { provider: string, roleArn: string, region: string }): any => {
    return (dispatch: any) => {
        dispatch({ type: USER_SAVE_CREDENTIALS, payload: payload });
    }
}




