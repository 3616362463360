import { ApiInstanceNoAuth } from './Instances';
import type { ApiResponse } from './interface';

interface AuthProviderData {
    email: string;
    auth_provider: 'google' | 'email';
    needs_password_setup?: boolean;
}

export type AuthProviderResponse = ApiResponse<AuthProviderData>;

export const httpOnCheckAuthProvider = (email: string): Promise<AuthProviderResponse> => {
    return new Promise((resolve, reject) => {
        const options = {
            params: {
                email: email
            }
        };
        ApiInstanceNoAuth.get<AuthProviderResponse>('/auth/provider', options)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response);
            });
    });
}; 