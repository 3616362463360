import { Box, Tooltip, Typography, Card, CardMedia, CardContent, CardActions } from '@mui/material'
import ImgThumbnailPlaceholder from '../Assets/resume-thumbnail-placeholder.svg'
import theme from '../theme'
import React from 'react'
import { Error } from '@mui/icons-material'


interface ResumeThumbnailProps {
    thumbnailUrl: string,
    onClick?: () => void,
    isResumeStatusFailed?: boolean,
    children?: React.ReactNode, // For buttons/actions
}
  

const ResumeThumbnail: React.FC<ResumeThumbnailProps> = ({ thumbnailUrl, onClick, isResumeStatusFailed, children }) => {

    return (
      <Card 
        elevation={2} 
        sx={{ 
          position: 'relative',
          transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
          '&:hover': {
            transform: onClick ? 'scale(1.02)' : 'none',
            boxShadow: onClick ? theme.shadows[4] : theme.shadows[2],
          },
          border: `1px solid ${theme.palette.action.active}`, 
          borderRadius: theme.spacing(1),
          width: '100%'
        }}
      >
          { isResumeStatusFailed && (
          <Tooltip title="Resume has validation error, please edit it." placement="top">
            <Error sx={{ position: 'absolute', right: theme.spacing(2), top: theme.spacing(1), zIndex: 1 }} color='error' />
          </Tooltip>)
          }

          <Box 
            onClick={onClick}
            sx={{ cursor: onClick ? 'pointer' : 'default' }}
          >
            {thumbnailUrl ? (
              <CardMedia
                component="img"
                sx={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain'
                }}
                image={"data:image/jpeg;base64," + thumbnailUrl}
                alt="Resume preview"
              />
            ) : (
              <CardMedia
                component="img"
                sx={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain'
                }}
                image={ImgThumbnailPlaceholder}
                alt="Resume preview placeholder"
              />
            )}
          </Box>

          {children && (
            <CardActions sx={{ 
              p: 2,
              pt: 1.5,
              gap: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap'
            }}>
              {children}
            </CardActions>
          )}

      </Card>
    )
}


export default ResumeThumbnail;