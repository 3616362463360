import UserPool from '../../Configs/UserPool';
import { CognitoUserSession, CognitoIdToken, CognitoAccessToken } from 'amazon-cognito-identity-js';

// Storage key prefix for all Cognito-related items
const STORAGE_KEY_PREFIX = 'CognitoIdentityServiceProvider';
const CLIENT_ID = process.env.REACT_APP_USER_POOL_CLIENT_ID || '';

interface UserData {
    id: string;
    email: string;
    name: string;
    image: string;
    accessToken: string;
    idToken: string;
}

const getStoredTokens = () => {
    // Try Cognito format first
    const userId = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`);
    if (userId) {
        const accessToken = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.accessToken`);
        const idToken = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.idToken`);
        if (accessToken && idToken) {
            return { accessToken, userId, idToken };
        }
    }

    // Try legacy format
    const legacyToken = localStorage.getItem('cognitoAccessToken');
    const legacyUserId = localStorage.getItem('userId');
    if (legacyToken && legacyUserId) {
        // Migrate to new format
        localStorage.setItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`, legacyUserId);
        localStorage.setItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${legacyUserId}.accessToken`, legacyToken);
        localStorage.setItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${legacyUserId}.idToken`, legacyToken);
        
        // Clean up legacy format
        localStorage.removeItem('cognitoAccessToken');
        localStorage.removeItem('userId');
        
        return { accessToken: legacyToken, userId: legacyUserId, idToken: legacyToken };
    }

    // Try simplified format (another legacy)
    const simpleUserId = localStorage.getItem('LastAuthUser');
    if (simpleUserId) {
        const simpleAccessToken = localStorage.getItem('accessToken');
        const simpleIdToken = localStorage.getItem('idToken');
        if (simpleAccessToken && simpleIdToken) {
            // Migrate to new format
            localStorage.setItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`, simpleUserId);
            localStorage.setItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${simpleUserId}.accessToken`, simpleAccessToken);
            localStorage.setItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${simpleUserId}.idToken`, simpleIdToken);
            
            // Clean up simplified format
            localStorage.removeItem('LastAuthUser');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('idToken');
            
            return { accessToken: simpleAccessToken, userId: simpleUserId, idToken: simpleIdToken };
        }
    }

    return null;
}

export const awsOnGetSession = (): Promise<UserData> => {
    return new Promise((resolve, reject) => {
        // Try stored tokens first
        const tokens = getStoredTokens();
        if (tokens) {
            resolve({ 
                id: tokens.userId,
                email: '', // These will be populated from Redux state
                name: '',
                image: '',
                accessToken: tokens.accessToken,
                idToken: tokens.idToken
            });
            return;
        }

        // Fall back to Cognito session
        const user = UserPool.getCurrentUser();
        if (user) {
            user.getSession((err: Error | null, session: CognitoUserSession | null) => {
                if (err) {
                    reject(err);
                    return;
                }

                if (!session || !session.isValid()) {
                    reject(new Error("Session is not valid"));
                    return;
                }

                const accessToken = session.getAccessToken();
                const idToken = session.getIdToken();
                const username = accessToken.payload.username;
                const email = idToken.payload.email;
                const nickname = idToken.payload.nickname;

                const userData = { 
                    id: username, 
                    email, 
                    name: nickname, 
                    image: '', 
                    accessToken: accessToken.getJwtToken(),
                    idToken: idToken.getJwtToken()
                };
                resolve(userData);
            });
        } else {
            reject(new Error("No current user found"));
        }
    });
}

const authHeaderName = 'Authorization';

export const setAuthHeader = async (config: Record<string, any>): Promise<Record<string, any>> => {
    try {
        // Try stored tokens first
        const tokens = getStoredTokens();
        if (tokens) {
            if (!config.headers) {
                config.headers = {};
            }
            config.headers[authHeaderName] = `Bearer ${tokens.accessToken}`;
            return config;
        }

        // Fall back to getting a fresh session
        const session = await awsOnGetSession();
        if (!config.headers) {
            config.headers = {};
        }
        config.headers[authHeaderName] = `Bearer ${session.accessToken}`;
        return config;
    } catch (err) {
        console.error("Error setting auth header:", err);
        throw err;
    }
};

const refreshToken = async () => {
    return new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (!user) {
            console.error('❌ No current user found in UserPool');
            reject(new Error('No current user'));
            return;
        }

        user.getSession((err: any, session: any) => {
            if (err) {
                console.error('❌ Error getting session:', err);
                reject(err);
                return;
            }

            if (!session.isValid()) {
                console.log('⚠️ Session expired, refreshing tokens...');
                const refreshToken = session.getRefreshToken();
                
                user.refreshSession(refreshToken, (err: any, newSession: any) => {
                    if (err) {
                        console.error('❌ Error refreshing session:', err);
                        reject(err);
                        return;
                    }
                    
                    // Get the new tokens
                    const accessToken = newSession.getAccessToken().getJwtToken();
                    const idToken = newSession.getIdToken().getJwtToken();
                    const userId = newSession.getAccessToken().payload.username;
                    
                    // Store tokens in Cognito format
                    localStorage.setItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.accessToken`, accessToken);
                    localStorage.setItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.idToken`, idToken);
                    
                    resolve(newSession);
                });
            } else {
                resolve(session);
            }
        });
    });
};

// Add a function to check if token needs refresh
const checkAndRefreshTokens = async () => {
    try {
        const tokens = getStoredTokens();
        if (!tokens) {
            return null;
        }

        // Decode the access token to check expiration
        const { accessToken } = tokens;
        const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
        const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
        const currentTime = Date.now();
        const timeUntilExpiration = expirationTime - currentTime;
        
        // If token expires in less than 5 minutes, refresh it
        if (timeUntilExpiration < 300000) {
            await refreshToken();
            return getStoredTokens();
        }
        
        return tokens;
    } catch (error) {
        console.error('❌ Error checking/refreshing tokens:', error);
        return null;
    }
};

// Utility function to decode JWT and get expiration
const getTokenExpiration = (token: string): number | null => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const payload = JSON.parse(jsonPayload);
        return payload.exp;
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

// For testing - force token to appear expired by modifying the stored token
const forceTokenExpiration = () => {
    const tokens = getStoredTokens();
    if (!tokens || !tokens.userId) return;

    try {
        // Decode the current token
        const base64Url = tokens.accessToken.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = atob(base64);
        const payload = JSON.parse(jsonPayload);
        
        // Modify the expiration time to be 1 minute ago
        payload.exp = Math.floor(Date.now() / 1000) - 60;
        
        // Reconstruct the token (Note: this is just for testing, as it breaks the signature)
        const modifiedPayload = btoa(JSON.stringify(payload)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
        const parts = tokens.accessToken.split('.');
        const modifiedToken = `${parts[0]}.${modifiedPayload}.${parts[2]}`;
        
        // Store the modified token
        localStorage.setItem(
            `${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${tokens.userId}.accessToken`,
            modifiedToken
        );
        
        console.log('Token expiration forced. Next API call should trigger a refresh.');
    } catch (error) {
        console.error('Error forcing token expiration:', error);
    }
};

// For testing - get time until token expires
const getTimeUntilExpiration = (): { accessToken: number } | null => {
    const tokens = getStoredTokens();
    if (!tokens) return null;

    const now = Math.floor(Date.now() / 1000);  // Current time in seconds
    const accessExp = getTokenExpiration(tokens.accessToken);

    return {
        accessToken: accessExp ? accessExp - now : 0
    };
};

// Make functions available globally for testing
declare global {
    interface Window {
        SessionTest: {
            getTimeUntilExpiration: typeof getTimeUntilExpiration;
            forceTokenExpiration: typeof forceTokenExpiration;
            checkAndRefreshTokens: typeof checkAndRefreshTokens;
        }
    }
}

if (process.env.NODE_ENV === 'development') {
    window.SessionTest = {
        getTimeUntilExpiration,
        forceTokenExpiration,
        checkAndRefreshTokens
    };
}

export {
    getTimeUntilExpiration,
    forceTokenExpiration,
    checkAndRefreshTokens
};