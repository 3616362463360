import React, { useState } from "react";
import { Box, IconButton, Typography, Theme, TextField, styled } from "@mui/material";
import {
  DeleteForever,
  Add as IconAdd,
  Close as CloseIcon,
} from '@mui/icons-material';
import { Controller, useFieldArray } from "react-hook-form";
import theme from "../../theme";
import { FormRemovedDetail } from "./FormRemovedDetail";
import { getId } from "./utils";

const PREFIX = 'ResumeForm';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
  
    display: "flex",
    flexDirection: 'column',
    alignItems: "left",
    justifyContent: "left",
  },

}));


// "projects": {
//   "anyOf": [
//       {
//           "items": {
//               "type": "string"
//           },
//           "type": "array"
//       },
//       {
//           "type": "null"
//       }
//   ],
//   "default": [],
//   "description": "Candidate project participation separate from experience",
//   "title": "Projects"
// }

// "projects": [
//   "QuantSoftwareToolkit: Open source Python library for financial data analysis.",
//   "GitHubVisualization: Data visualization of Git log data using D3.",
//   "RecommendationSystem: Music and movie recommender systems using collaborative filtering.",
//   "MacSetup: Book that gives step-by-step instructions on setting up a developer environment on macOS."
// ],




interface ResumeFormProjectProps {
  control: any,
  _required?: boolean,
  diffOriginData?: any,
  showDiff: boolean,
}


const ResumeFormProject: React.FC<ResumeFormProjectProps> = ({ control, _required, diffOriginData, showDiff }) => {
  // eslint-disable-next-line
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'projects', // unique name for your Field Array
  });

  const projects = control._formValues?.projects;

  const [state, setState] = useState<number>(0); // solely to trigger re-render for to have updated awards variable in the component
  const [deletingProjectIndex, setDeletingProjectIndex] = useState<number | null>(null);

  return <Root className={classes.root}>
    <Typography>Projects
      <IconButton onClick={() => {append('')}}
        disabled={projects?.length > 0 && projects[projects.length -1] === ''}>
        <IconAdd/>
      </IconButton>
    </Typography>

    {fields.map((field: any, index: number) => (
      <Box key={field.id}>
        <Controller
          name={`projects.${index}`}
          control={control}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Box sx={{ 
              display: 'flex',
              alignItems: 'center', // Center items vertically
              gap: 1, // Add consistent spacing between elements
              mb: 1, // Add margin bottom between project entries
            }}>
              <Box sx={{ flexGrow: 1 }}>
                <TextField
                  id={getId(name)}
                  value={value}
                  onChange={(newVal) => {onChange(newVal); setState(state + 1)}}
                  size="small"
                  style={{ width: '100%' }}
                  multiline={true}
                  sx={{
                    '& .MuiInputBase-root': {
                      ...(deletingProjectIndex === index && {
                        '& .MuiInputBase-input': {
                          color: `${theme.palette.error.main} !important`,
                          textDecoration: 'line-through !important',
                        }
                      })
                    }
                  }}
                />
              </Box>

              <Box>
                {deletingProjectIndex === index ? (
                  <Box sx={{ 
                    display: 'flex', 
                    gap: 0.5,
                    alignItems: 'center' // Center the delete confirmation buttons
                  }}>
                    <IconButton 
                      size="small"
                      onClick={() => {
                        remove(index);
                        setDeletingProjectIndex(null);
                      }}
                      sx={{ color: theme.palette.error.main }}
                    >
                      <DeleteForever/>
                    </IconButton>
                    <IconButton 
                      size="small"
                      onClick={() => setDeletingProjectIndex(null)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <IconButton 
                    onClick={() => setDeletingProjectIndex(index)}
                    size="small"
                  >
                    <DeleteForever/>
                  </IconButton>
                )}
              </Box>
            </Box>
          )}
        />
      </Box>
    ))}

    {/* Removed projects */}
    { showDiff && (diffOriginData?.projects?.length > fields?.length) &&
      [...(diffOriginData?.projects || [])].slice(fields?.length || 0).map((field: any, index: number) => (

      <FormRemovedDetail val={field} onChangeDetails={(newValues) => {append(newValues[newValues.length - 1])}} values={fields} />

    ))}

  </Root>
}



export default ResumeFormProject;