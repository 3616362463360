import React from "react";
import { Box, IconButton, TextField } from "@mui/material";
import { formDiffRedColor } from "./styles";
import theme from "../../theme";
import { Undo } from "@mui/icons-material";



interface Props {
  val: any
  onChangeDetails: (...event: any[]) => void
  values: any
}

export const FormRemovedDetail: React.FC<Props> = ({val, onChangeDetails, values}) => {
    return <Box>
      <Box sx={{ display: 'flex' }}>
  
        <Box sx={{ flexGrow: '1' }}>
          <TextField
            value={val}
            size="small"
            style={{ width: '100%', background: formDiffRedColor }}
            multiline={true}
            sx={{
              mb: theme.spacing(0.5), '& .MuiInputBase-root .MuiInputBase-input': {
                background: formDiffRedColor,
                WebkitTextFillColor: theme.palette.text.secondary
              }
            }}
            disabled={true} />
  
  
        </Box>
  
        <Box>
          <IconButton onClick={() => { onChangeDetails([...values, val]); } }>
            <Undo />
          </IconButton>
        </Box>
  
      </Box>
  
  
    </Box>;
};