import axios from 'axios';
import { awsOnGetSession } from '../Aws/Session';

const authHeaderName = 'Authorization';

export const setAuthHeader = async (config: any): Promise<any> => {
    try {
        const session = await awsOnGetSession();
        if (!config.headers) {
            config.headers = {};
        }
        config.headers[authHeaderName] = `Bearer ${session.accessToken}`;
        return config;
    } catch (err) {
        console.error("Error setting auth header:", err);
        throw err;
    }
};

// Helper to ensure consistent URL handling
const normalizeBaseUrl = (url: string | undefined) => {
    if (!url) return '';
    return url.endsWith('/') ? url.slice(0, -1) : url;
};

export const ApiInstance = axios.create({
    baseURL: normalizeBaseUrl(process.env.REACT_APP_API_BASE_URL)
});

export const ApiInstanceNoAuth = axios.create({
    baseURL: normalizeBaseUrl(process.env.REACT_APP_PUBLIC_API_BASE_URL)
});

// Add auth header interceptor
ApiInstance.interceptors.request.use(setAuthHeader);

