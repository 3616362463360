import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Alert,
  LinearProgress,
  Link,
  useTheme,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../Components/Button';
import ImgLogo from '../Assets/logo-net-dark.png';
import { awsOnForgotPassword, awsOnConfirmForgotPassword } from '../Services/Aws/User';

const ForgotPassword: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialEmail = location.state?.email || searchParams.get('email') || '';

  const [email, setEmail] = useState(initialEmail);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [passwordScore, setPasswordScore] = useState<number | null>(null);
  const [passwordScoreFeedback, setPasswordScoreFeedback] = useState<any>(null);

  function checkPassword(zxcvbn: any, password: string, minLength = 8) {
    if (password.length < minLength) {
      return {
        score: 0,
        feedback: {
          warning: `Password must be at least ${minLength} characters long`,
          suggestions: ['Add more characters to meet the minimum length requirement']
        }
      };
    }
    
    return zxcvbn(password);
  }

  useEffect(() => {
    if (newPassword) {
      import('zxcvbn').then((zxcvbn) => {
        const result: any = checkPassword(zxcvbn.default, newPassword);
        setPasswordScore(result.score);
        setPasswordScoreFeedback(result.feedback);
      });
    } else {
      setPasswordScore(null);
    }
  }, [newPassword]);

  // Function to map password score to a color
  const getColorForStrength = (score: number) => {
    if (score <= 1) return theme.palette.error.main;
    if (score === 2) return theme.palette.warning.main;
    if (score === 3) return theme.palette.success.main;
    return theme.palette.success.main;
  };

  const getSectionColors = () => {
    const colors = ['grey', 'grey', 'grey', 'grey', 'grey'];
    if (passwordScore !== null) {
      for (let i = 0; i <= passwordScore; i++) {
        colors[i] = getColorForStrength(passwordScore);
      }
    }
    return colors;
  };

  const sectionColors = getSectionColors();

  const handleSendCode = async () => {
    if (!email) {
      setError('Please enter your email address');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      await awsOnForgotPassword(email);
      setCodeSent(true);
    } catch (err: any) {
      setError(err.message || 'Failed to send verification code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (!verificationCode) {
      setError('Please enter the verification code');
      return;
    }

    if (!newPassword) {
      setError('Please enter a new password');
      return;
    }

    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }

    if (passwordScore && passwordScore < 3) {
      setError('Please use a stronger password');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      await awsOnConfirmForgotPassword(email, verificationCode, newPassword);
      // Show success message and redirect to login
      navigate('/login', { 
        state: { 
          message: 'Password reset successful. Please login with your new password.',
          severity: 'success'
        } 
      });
    } catch (err: any) {
      setError(err.message || 'Failed to reset password. Please try again.');
      setIsLoading(false);
    }
  };

  const handleBackToLogin = () => {
    navigate('/login');
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        background: `linear-gradient(135deg, ${theme.palette.primary.backgroundLight} 30%, ${theme.palette.secondary.backgroundLight} 90%)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.3)',
          pointerEvents: 'none',
        },
      }}
    >
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          borderRadius: 4,
          boxShadow: 5,
          overflow: 'hidden',
          width: '100%',
          maxWidth: { xs: '90%', sm: 500, md: 800 },
          m: 2,
          bgcolor: 'background.paper',
        }}
      >
        {/* Left side - Form */}
        <Box
          sx={{
            flex: { xs: '1', md: '1' },
            p: 4,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isLoading && (
            <LinearProgress
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                borderTopLeftRadius: 4,
              }}
            />
          )}

          <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
            <img src={ImgLogo} alt="Logo" style={{ height: '40px' }} />
          </Box>

          <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
            Reset Password
          </Typography>

          <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', color: 'text.secondary' }}>
            {!codeSent 
              ? "Enter your email address and we'll send you a verification code."
              : "Enter the verification code sent to your email and set your new password."
            }
          </Typography>

          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

          {!codeSent ? (
            <>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                sx={{ mb: 3 }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
              />

              <Button
                label="Send Verification Code"
                onClick={handleSendCode}
                disabled={isLoading}
                sx={{ mb: 2 }}
              />
            </>
          ) : (
            <>
              <TextField
                label="Verification Code"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                disabled={isLoading}
              />

              <TextField
                label="New Password"
                type="password"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                disabled={isLoading}
              />

              {/* Display password strength */}
              {passwordScore !== null && (
                <Box sx={{position: 'relative', minHeight: theme.spacing(8), mb: 2}}>
                  <Box p={1} sx={{border: '1px solid ' + theme.palette.secondary.main,
                    position: 'absolute',
                    background: 'white',
                    zIndex: 1000,
                    width: '100%',
                    borderRadius: theme.spacing(1)}}>
                    <Typography variant="body2" pb={1}>
                      Password Strength: {['Very Weak', 'Weak', 'Moderate', 'Strong', 'Very Strong'][passwordScore]}
                    </Typography>
                    {passwordScoreFeedback?.warning && (
                      <Typography variant="body2" color="warning.main" pb={1}>
                        Warning: {passwordScoreFeedback?.warning}
                      </Typography>
                    )}
                    {passwordScoreFeedback?.suggestions &&
                      passwordScoreFeedback?.suggestions?.length > 0 && (
                        <>
                          <Typography variant="body2">Suggestions:</Typography>
                          <ul>
                            {passwordScoreFeedback?.suggestions?.map((suggestion: any, index: any) => (
                              <Typography variant="body2" component="li" key={index}>
                                {suggestion}
                              </Typography>
                            ))}
                          </ul>
                        </>
                      )}
                    <Box display="flex" flexDirection="row" mt={1} sx={{justifyContent: 'space-between'}}>
                      {[0, 1, 2, 3, 4].map((index) => (
                        <LinearProgress
                          key={`progress-${index}`}
                          variant="determinate"
                          value={100}
                          sx={{ '& > span': {background: sectionColors[index]}}}
                          style={{ height: '5px', marginBottom: '2px', width: '15%' }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              )}

              <TextField
                label="Confirm New Password"
                type="password"
                variant="outlined"
                fullWidth
                sx={{ mb: 3 }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                disabled={isLoading}
              />

              <Button
                label="Reset Password"
                onClick={handleResetPassword}
                disabled={isLoading}
                sx={{ mb: 2 }}
              />

              <Button
                label="Resend Code"
                onClick={handleSendCode}
                disabled={isLoading}
                sx={{ 
                  mb: 2,
                  color: 'text.secondary',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline'
                  }
                }}
              />
            </>
          )}

          <Link
            component="button"
            variant="body2"
            onClick={handleBackToLogin}
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Back to Login
          </Link>
        </Box>

        {/* Right side - Image/Decoration */}
        <Box
          sx={{
            flex: { xs: '0', md: '1' },
            bgcolor: 'primary.light',
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
            p: 4,
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}40 30%, ${theme.palette.secondary.main}40 90%)`,
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: 'common.white',
              textAlign: 'center',
              position: 'relative',
              fontWeight: 'light',
              lineHeight: 1.5,
            }}
          >
            Recover Your
            <br />
            Account Access
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default ForgotPassword; 