import React from 'react'
import { styled } from '@mui/material/styles';
import { PulseLoader, ClipLoader } from "react-spinners";
import { LinearProgress, Theme } from '@mui/material';
import theme from '../theme';
import clsx from 'clsx';

const PREFIX = 'Loading';

const classes = {
    bubble: `${PREFIX}-bubble`,
    linear: `${PREFIX}-linear`
};

const Root = styled('span')(({ theme }: { theme: Theme }) => ({

    [`&.${classes.bubble}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },

    [`&.${classes.linear}`]: {
        width: '100%',
        display: 'block'
    }
}));

type Type = "bubble" | "linear" | "bubbleMini" | "circular";

interface Props {
    open: boolean,
    color?: any,
    type: Type,
    className?: any,
    size?: number
}

const Loading: React.FC<Props> = ({ type, open, color, className, size }) => {


    if (open) {
        if (type === 'bubble') {
            return (
                <Root className={clsx(classes.bubble, className)}>
                    <PulseLoader
                        size={size}
                        color={color || theme.Loader.color}
                    />
                </Root>
            );
        } else if (type === 'linear') {
            return (
                <Root className={classes.linear}>
                    <LinearProgress />
                </Root>
            )
        }
        else if (type === 'circular') {
            return (
                <Root className={clsx(classes.bubble, className)}>
                    <ClipLoader
                        size={size}
                        color={color || theme.Loader.color}
                    />
                </Root>
            )
        }
    }
    return null;
}

export default Loading;