import React, { useState } from 'react';
import { Box, Typography, TextField, Alert, LinearProgress, Container, useTheme } from '@mui/material';
import Button from '../Components/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { awsOnCompleteNewPassword } from '../Services/Aws/User';
import { useDispatch } from 'react-redux';
import { onUserSaveAction } from '../Redux/User/Actions';
import ImgLogo from '../Assets/logo-net-dark.png';

const NewPasswordRequired: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [inProgress, setInProgress] = useState(false);

  // Get email from location state
  const email = location.state?.email;
  if (!email) {
    // If no email in state, redirect to login
    navigate('/login');
    return null;
  }

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }

    setInProgress(true);
    setError('');
    setSuccess('');

    try {
      const user = await awsOnCompleteNewPassword(email, newPassword);
      dispatch(onUserSaveAction(user));
      setSuccess('Password set successfully! Redirecting...');
      setTimeout(() => {
        navigate('/app');
      }, 1500);
    } catch (err: any) {
      setError(err.message || 'Failed to set new password. Please try again.');
    } finally {
      setInProgress(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        background: `linear-gradient(135deg, ${theme.palette.primary.backgroundLight} 30%, ${theme.palette.secondary.backgroundLight} 90%)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.3)',
          pointerEvents: 'none',
        },
      }}
    >
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          borderRadius: 4,
          boxShadow: 5,
          overflow: 'hidden',
          width: '100%',
          maxWidth: { xs: '90%', sm: 500, md: 800 },
          m: 2,
          bgcolor: 'background.paper',
        }}
      >
        {/* Left side - Form */}
        <Box
          sx={{
            flex: { xs: '1', md: '1' },
            p: 4,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {inProgress && (
            <LinearProgress
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                borderTopLeftRadius: 4,
              }}
            />
          )}

          <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
            <img src={ImgLogo} alt="Logo" style={{ height: '40px' }} />
          </Box>

          <Typography variant="h5" sx={{ mb: 3, textAlign: 'center' }}>
            Set New Password
          </Typography>

          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

          <TextField
            label="New Password"
            type="password"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            disabled={inProgress}
          />

          <TextField
            label="Confirm New Password"
            type="password"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={inProgress}
          />

          <Button
            label="Set Password"
            onClick={handleSubmit}
            disabled={inProgress}
            sx={{ mb: 2 }}
          />
        </Box>

        {/* Right side - Image/Decoration */}
        <Box
          sx={{
            flex: { xs: '0', md: '1' },
            bgcolor: 'primary.light',
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
            p: 4,
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}40 30%, ${theme.palette.secondary.main}40 90%)`,
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: 'common.white',
              textAlign: 'center',
              position: 'relative',
              fontWeight: 'light',
              lineHeight: 1.5,
            }}
          >
            Secure Your
            <br />
            Account Access
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default NewPasswordRequired; 