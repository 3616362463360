import React, { useEffect } from "react";
import {  FormGroup, LinearProgress, Paper, Switch, Box,  Theme, styled,Typography, Button, Popover, Alert } from '@mui/material';
import useUser from "../Hooks/useUser";
import { useDispatch } from "react-redux";
import { onAlertShowAction } from "../Redux";
import { formatErrorMessageHtml, httpOnGetUser, httpOnPutUserPreferences } from "../Services/Http";
import { IUserPreferences } from "../Services/Http/interface";
import { Settings } from "@mui/icons-material";

const debugLogs = process.env.REACT_APP_DEBUG_LOGS;

const PREFIX = 'UserPreferencesModal';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  box: `${PREFIX}-box`,
  dragNdrop: `${PREFIX}-dragNdrop`,
  modalRoot: `${PREFIX}-modalRoot`,
  textInput: `${PREFIX}-textInput`,
  iconContainer: `${PREFIX}-iconContainer`,
  error: `${PREFIX}-error`,
  button: `${PREFIX}-button`,
  browseFilesButton: `${PREFIX}-browseFilesButton`,
  title: `${PREFIX}-title`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    
  },

  [`& .${classes.container}`]: {
    position: "absolute",
    borderRadius: 7,
    width: "50%",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    margin: theme.spacing(2),
    backgroundColor: theme.colors.white,

  },

  [`& .${classes.box}`]: {
    alignItems: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.dragNdrop}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    borderRadius: 7,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,

  },

  [`& .${classes.modalRoot}`]: {
    width: "100%",
  },

  [`& .${classes.textInput}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "center",
    marginTop: theme.spacing(3),
  },

  [`& .${classes.iconContainer}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },

  [`& .${classes.error}`]: {
    color: theme.colors.error,
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3)
  },

  [`& .${classes.browseFilesButton}`]: {
    marginTop: theme.spacing(2)
  },

  [`& .${classes.title}`]: {
    fontSize: theme.fontSize.lg,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
}));

interface Props {
  onChange?: (value: IUserPreferences) => void;
  isFormViewMode: boolean;
}


const UserPreferences: React.FC<Props> = ({ onChange, isFormViewMode }) => {
  const { userId } = useUser();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [saveInProgress, setSaveInProgress] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [userPreferencesData, setUserPreferencesData] = React.useState<IUserPreferences>({
    use_skills_first_layout: false,
    exclude_education_dates: false,
    show_objective_statement: true
  });


  const dispatch = useDispatch();


  React.useEffect(() => {

    async function fetchData() {
      setIsLoading(true);

      try {
        debugLogs && console.log('httpOnGetUser')
        const res: any = await httpOnGetUser(userId)

        if (res?.data?.user_preferences) {
          setUserPreferencesData(res.data.user_preferences);
        }

        setIsLoading(false);

      } catch (e) {
        const errorMsg = 'Unable to fetch User Preferences, please refresh the page or try again later.';
        displayError(errorMsg, formatErrorMessageHtml(errorMsg, e))
        setIsLoading(false);
        return;
      }

      setErrorMessage('');
    }

    fetchData();
  // eslint-disable-next-line 
  }, []);


  const handleSave = async (userPreferencesData: any) => {

    setUserPreferencesData(userPreferencesData);

    if (isFormViewMode) {
      return; // do not save changes in form view mode
    }

    setSaveInProgress(true);

    try {
      debugLogs && console.log('httpOnPutUserPreferences')
      // eslint-disable-next-line 
      const res: any = await httpOnPutUserPreferences(userId, userPreferencesData)
      setSaveInProgress(false);
    } catch (e) {
      const errorMsg = 'Unable to save changes, please try again later.';
      displayError(errorMsg, formatErrorMessageHtml(errorMsg, e))
      setSaveInProgress(false);
      return;
    }

  }

  useEffect(() => {
    onChange && onChange(userPreferencesData);
  }, [userPreferencesData]);


  const displayError = (errorMessage: string, errorMessageHtml: string) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessageHtml);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
      <Root
        className={classes.root}>
        {
          isFormViewMode ?
          <>
            <Button onClick={handleClick} startIcon={<Settings/>}>
              Preferences
            </Button>
            <Popover
              sx={{mt:1, padding: '8px'}}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box sx={{padding: '8px'}}>
                <Box display={'flex'} flexDirection='column' pt={2} sx={{ flexWrap: { xs: 'wrap' }, justifyContent: 'end' }}>
                  { getToggles(isLoading, userPreferencesData, handleSave) }
                </Box>
              </Box>
            </Popover>
          </>
          :
          <Box>
            <Paper>
              { (isLoading || saveInProgress) && <Box>
                <LinearProgress sx={{marginTop: '-4px'}}/>
              </Box>}

              <Box display={'flex'} flexDirection='column' pt={2}>
                <Box pl={1} pb={1}>
                  <Typography variant="h6" gutterBottom>
                    User Preferences
                  </Typography>
                </Box>

                { getToggles(isLoading, userPreferencesData, handleSave) }

              </Box>

              { errorMessage &&
                <Box pb={2} pt={1}>
                  <Alert severity="error">
                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  </Alert>
                </Box>
              }


            </Paper>
          </Box>

        }

      </Root>
  );
};


const getToggles = (isLoading: boolean, userPreferencesData: any, handleSave: any) => {
  return (<>
    <Box pl={1} pb={1}>
      <FormGroup row style={{ justifyContent: "start", width: "100%" }}>
        <Typography variant="subtitle2" gutterBottom sx={{ width: 180 }}>
          Use Skills First Layout
        </Typography>
        <Switch sx={{ marginTop: '-8px' }} disabled={isLoading}
          checked={userPreferencesData?.use_skills_first_layout} onChange={(e) => handleSave({ ...userPreferencesData, use_skills_first_layout: e.target.checked })} />
      </FormGroup>
    </Box>
    <Box pl={1} pb={1}>
      <FormGroup row style={{ justifyContent: "start", width: "100%" }}>
        <Typography variant="subtitle2" gutterBottom sx={{ width: 180 }}>
          Exclude Education Dates
        </Typography>
        <Switch sx={{ marginTop: '-8px' }} disabled={isLoading}
          checked={userPreferencesData?.exclude_education_dates} onChange={(e) => handleSave({ ...userPreferencesData, exclude_education_dates: e.target.checked })} />
      </FormGroup>
    </Box>
    <Box pl={1} pb={1}>
      <FormGroup row style={{ justifyContent: "start", width: "100%" }}>
        <Typography variant="subtitle2" gutterBottom sx={{ width: 180 }}>
          Show Summary Statement
        </Typography>
        <Switch sx={{ marginTop: '-8px' }} disabled={isLoading}
          checked={userPreferencesData?.show_objective_statement} onChange={(e) => handleSave({ ...userPreferencesData, show_objective_statement: e.target.checked })} />
      </FormGroup>
    </Box>
  </>);
}

export default UserPreferences;
