import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Fab, IconButton, Paper, Popper, Typography, Badge, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorOutline, ExpandMore } from '@mui/icons-material';
import type { IValidationError } from '../../Services/Http/interface';

interface ResumeFormErrorProps {
  errorMessage: string,
  errorMessages: IValidationError[],
  scrollToElementByPath?: (path: string[]) => void
}

const ResumeFormError: React.FC<ResumeFormErrorProps> = (({ errorMessage, errorMessages, scrollToElementByPath}) => {

  return (
    <>
    {errorMessage &&
      <Box pb={2} pt={1}>
        {errorMessages?.length > 0 ?

          <Accordion defaultExpanded={true} sx={{
            backgroundColor: 'rgb(254, 243, 243)',
            color: 'rgb(99, 56, 56)',
          }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
            >
              <Box sx={{display: 'flex'}}>
                <ErrorOutline sx={{color: '#F87171'}}/>
                <Typography
                  sx={{ fontWeight: 'bold', pt: '1px', pl: 1 }}
                  dangerouslySetInnerHTML={{ __html: errorMessage }}
                />
              </Box>

            </AccordionSummary>
            <AccordionDetails sx={{pt:0}}>

              <ul>
                {errorMessages?.map((message, index) => <li>
                  <Typography
                    // component={message.path ? Link : 'span'}
                    onClick={() => message.path && scrollToElementByPath && scrollToElementByPath(message.path)}
                    sx={{display: 'inline', textDecoration: 'underline', cursor: 'pointer'}}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: message.message }}
                  >
                  </Typography>
                </li>)}
              </ul>
            </AccordionDetails>
          </Accordion>
          :
          <Box sx={{display: 'flex'}}>
            <ErrorOutline sx={{color: '#F87171'}}/>
            <Typography
            sx={{ fontWeight: 'bold', pt: '1px', pl: 1 }}
            >{errorMessage}</Typography>
          </Box>
        }
      </Box>
    }
    </>
  );
});

export default ResumeFormError;