import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, CircularProgress, Icon, IconButton, LinearProgress, Link, List, ListItem, Tooltip, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { Cancel, DeleteForever, ExpandMore } from "@mui/icons-material";
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp';
import { useNavigate, useParams } from "react-router-dom";
import { httpOnEditResume, httpOnDeleteResume, httpPollResumeExtracted, parseFormValidationError, validationErrorsToMessages, formatErrorMessageHtml } from "../Services/Http";
import useUser from "../Hooks/useUser";
import { useDispatch } from "react-redux";
import { onAlertShowAction } from "../Redux/Alert/Actions";
import ResumeForm, { IResumeFormRef } from "../Components/ResumeForm/ResumeForm";
import { formatUpdatedAt } from "../Services/Util/dateFormat";
import ResumeTitle from "../Components/ResumeTitle";
import ConfirmDialogBox from "../Components/ConfirmDialogBox";
import theme from "../theme";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ResumeFormError from "../Components/ResumeForm/ResumeFormError";
import { stickySubheaderStyles } from "../Components/ResumeForm/styles";
import FeedbackSidePopup from "../Components/FeedbackSidePopup";
import { IValidationError } from "../Services/Http/interface";

const debugLogs = process.env.REACT_APP_DEBUG_LOGS;

// src/pages/EditResume.js
export default function EditResume() {

  const [fileName, setFileName] = React.useState<string>("");
  const [fileUpdatedAt, setFileUpdatedAt] = React.useState<string>("");

  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [errorMessages, setErrorMessages] = useState<IValidationError[]>([]);

  const [saveInProgress, setSaveInProgress] = React.useState<boolean>(false);
  const [resumeData, setResumeData] = React.useState<any>(null);
  const [removeResumeInProgress, setRemoveResumeInProgress] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [removeResumeDialogOpen, setRemoveResumeDialogOpen] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const params = useParams();
  const { userId } = useUser();

  const dispatch = useDispatch();

  const formRef = useRef<IResumeFormRef>(null);

  const submitButtonText = 'Save Changes';

  const navigateToResourcesView = (resumeB62Id: string) => navigate(`/app/${resumeB62Id}/folder`);

  React.useEffect(() => {
    setIsLoading(true);
    httpPollResumeExtracted(params.resumeB62Id!, userId).then((res: any) => {
      const resume = res.data;
      setFileName(resume.file_name);
      setFileUpdatedAt(formatUpdatedAt(resume.updated_at));
      setResumeData(resume.resume_details);

      setTimeout(() => {
        setIsLoading(false);
      }, 2000); // give time for the form to render, and prevent submit while it is still rendering

    }).catch((error: any) => {

      setIsLoading(false);
      if (error?.data?.status === 'Failed') {

        if (error?.data?.status_message_details) {
          displayValidationError(error?.data?.status_message,
            validationErrorsToMessages(error?.data?.status_message_details || []));
        } else {
          const errorMessage = "Failed to load resume data";
          displayError(errorMessage, formatErrorMessageHtml(errorMessage, error));
        }

        const resume = error?.data;
        setFileName(resume?.file_name);
        setFileUpdatedAt(formatUpdatedAt(resume?.updated_at));
        setResumeData(resume?.resume_details || null);
        setIsLoading(false);

      } else {
        const errorMessage = "Failed to load resume data";
        displayError(errorMessage, formatErrorMessageHtml(errorMessage, error));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.resumeB62Id]);

  const displayValidationError = (errorMessage: string, errorMessages: IValidationError[] = []) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessage);
    setErrorMessages(errorMessages);
    setSaveInProgress(false);
  };

  const displayError = (errorMessage: string, errorMessageHtml: string) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessageHtml);
    setErrorMessages([]);
    setSaveInProgress(false);
  }

  const handleSave = async (resumeData: any) => {
    setSaveInProgress(true);

    setResumeData(resumeData);

    try {
      debugLogs && console.log('httpOnEditResume');
      // eslint-disable-next-line 
      const res: any = await httpOnEditResume(params.resumeB62Id!, resumeData, userId);
      setSaveInProgress(false);
    } catch (e) {

      const { title, messages } = parseFormValidationError(e);
      if (messages.length) {
        displayValidationError(title, messages);
      } else {
        displayError(title, formatErrorMessageHtml(title, e));
      }

      setSaveInProgress(false);
      return;
    }

    backToHome();

  };

  const removeResume = async (resumeB62Id: string) => {
    setRemoveResumeInProgress(true);
    try {
      await httpOnDeleteResume(resumeB62Id, userId);
    } catch (e) {
      setRemoveResumeInProgress(false);
      return;
    }
    setRemoveResumeInProgress(false);
    navigate(`/app`);
  };

  const backToHome = () => navigate(-1);

  return (
    <Box pt={3}>

      <Box sx={stickySubheaderStyles}>

        <Box pb={3} sx={{ 'display': 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: { xs: 'wrap', sm: 'nowrap' }, gap: 2 }}>

          <Box sx={{ 'display': 'flex', alignItems: 'center', gap: 1, flexGrow: 1 }}>
            {params.resumeB62Id &&
              <>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1,
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: theme.spacing(1.5),
                  padding: theme.spacing(1.5),
                  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.08)'
                }}>
                  <ResumeTitle fileName={fileName} resumeUpdatedAt={fileUpdatedAt} />
                  <Box sx={{ 'display': 'flex', alignItems: 'center' }}>
                    <Tooltip title={'Delete the resume'} placement="top" arrow>
                      <span>
                        <IconButton 
                          color={'error'} 
                          onClick={() => setRemoveResumeDialogOpen(true)} 
                          disabled={saveInProgress || removeResumeInProgress}
                          sx={{
                            '&:hover': {
                              backgroundColor: theme.palette.error.main,
                              '& svg': {
                                color: 'white'
                              }
                            }
                          }}
                        >
                          <DeleteForever />
                        </IconButton>
                      </span>
                    </Tooltip>
                    {removeResumeInProgress && <CircularProgress sx={{
                      width: '20px !important', height: '20px !important'
                    }} />}
                  </Box>
                </Box>
                <Tooltip title={'View previously added job descriptions and resumes'} placement="top" arrow>
                  <span>
                    <IconButton 
                      color={'primary'}
                      onClick={() => { navigateToResourcesView(params.resumeB62Id!); }} 
                      disabled={saveInProgress || removeResumeInProgress}
                      sx={{ ml: 2 }}
                    >
                      <GridViewSharpIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            }
          </Box>

          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => backToHome()}
              startIcon={<Cancel />}
            >
              Cancel
            </Button>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              disabled={isLoading || saveInProgress}
              onClick={() => formRef.current?.submit()}
              sx={{
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  borderColor: theme.palette.primary.main,
                  transform: 'scale(1.02)',
                  transition: 'all 0.2s'
                }
              }}
            >
              {submitButtonText}
            </Button>
          </Box>
        </Box>

        {(isLoading || saveInProgress) && <Box>
          <LinearProgress sx={{ marginTop: '-4px' }} />
        </Box>}

        <ResumeFormError errorMessage={errorMessage}
          errorMessages={errorMessages}
          scrollToElementByPath={formRef?.current?.scrollToElementByPath}/>
      </Box>

      <ResumeForm
        data={resumeData}
        onDataChange={(data) => { handleSave({ ...resumeData, ...data }); }}
        disabled={isLoading || saveInProgress || removeResumeInProgress}
        ref={formRef}
        hideSubmitButton={true}
        submitText={submitButtonText}
        submitInProgress={saveInProgress}
        showDiff={false}
        showPersonalStatementsSection={true}
      />


      {/* <Box pb={3}>
              <Button
                variant="contained"
                disabled={saveInProgress}
                onClick={() => handleSave()}
                >
                Save
              </Button>
            </Box> */}

      <FeedbackSidePopup></FeedbackSidePopup>

      <ConfirmDialogBox
        title="Please confirm"
        message="Please confirm you want to delete this resume!"
        open={removeResumeDialogOpen}
        primaryButton={{ label: 'Delete', onClick: () => { removeResume(params.resumeB62Id!); setRemoveResumeDialogOpen(false); } }}
        secondaryButton={{ label: 'Cancel', onClick: () => setRemoveResumeDialogOpen(false) }}
        fullWidth
        onClose={() => setRemoveResumeDialogOpen(false)} />

    </Box>
  );
}
