import { Box, Button, CircularProgress, IconButton, LinearProgress,  Menu, MenuItem, MenuList,  Tooltip, Typography, FormControlLabel, Switch, Alert, Chip, Stack, Grid } from "@mui/material";
import React, { useEffect } from "react";
import FileUpload from "../Components/FileUpload";
import Textarea from "../Components/TextArea";
import { ArrowBack, AutoAwesome, DeleteForever, Settings, Check, Description, Upload } from "@mui/icons-material";
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp';
import { useNavigate, useParams } from "react-router-dom";
import { httpOnCreateTailored,  httpOnGetResumes, httpOnDeleteResume, httpOnSaveJobDescription, httpPollJobDescriptionExtracted, httpPollResumeExtracted, httpPollTailoredExtracted, iDetailedStatusContextWithCallback, httpOnCreateLatex, formatErrorMessageHtml } from "../Services/Http";
import useUser from "../Hooks/useUser";
//import FeedbackForm from "../Components/FeedbackForm";
import { useDispatch } from "react-redux";
import { onAlertShowAction } from "../Redux/Alert/Actions";
import { formatUpdatedAt } from "../Services/Util/dateFormat";
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import LlmAdvancedSettings, { ILlmAdvancedSettings } from "../Components/LlmAdvancedSettings";
import ResumeCard from "../Components/ResumeCard";
import FeedbackSidePopup from "../Components/FeedbackSidePopup";


const debugLogs = process.env.REACT_APP_DEBUG_LOGS;

const UNPIN = 'select-resume';

const textFieldStyle: SxProps<Theme> = {
  height: '100%',
  '& .MuiInputBase-input': {
    fontFamily: '"Courier New", Courier, monospace',
    lineHeight: '1.5'
  },
};

const StepChip = ({ 
  label, 
  completed, 
  active, 
  icon,
  stepNumber 
}: { 
  label: string, 
  completed: boolean, 
  active: boolean,
  icon: React.ReactElement,
  stepNumber: number
}) => {
  const theme = useTheme();

  return (
    <Chip
      deleteIcon={completed ? <Check /> : undefined}
      onDelete={completed ? () => {} : undefined}
      icon={completed ? undefined : icon}
      label={`Step ${stepNumber}: ${label}`}
      color={completed ? "success" : active ? "primary" : "default"}
      variant={active && !completed ? "outlined" : "filled"}
      sx={{
        cursor: 'default',
        ...(completed && {
          '& *': {
            color: 'rgba(0, 0, 0, 0.8)',
          }
        }),
        '& .MuiChip-icon': {
          color: completed ? 'inherit' : active ? 'primary.main' : 'action.disabled',
          order: 1,
          marginRight: '8px',
          marginLeft: '-2px',
          width: '20px',
          height: '20px'
        },
        '& .MuiChip-deleteIcon': {
          order: 0,
          marginRight: '4px',
          marginLeft: '-2px',
          width: '20px',
          height: '20px',
          cursor: 'default',
          '&:hover': {
            opacity: 1  // Prevent opacity change on hover
          }
        },
        '& .MuiChip-label': {
          paddingRight: '8px',
          cursor: 'default'
        },
        '&:hover .MuiChip-deleteIcon': {
          opacity: 1  // Prevent opacity change on chip hover
        },
        height: '32px',
        transition: 'all 0.2s ease-in-out',
        '& .MuiChip-labelMedium': {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        position: 'relative',
        overflow: 'hidden',
        ...(active && !completed && {
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: '-100%',
            width: '100%',
            height: '100%',
            background: `linear-gradient(90deg, transparent, ${theme.palette.primary.main}33, rgba(255,255,255,0.90))`,
            animation: 'shimmerEffect 2.5s infinite linear',
            filter: 'blur(5px)',
            pointerEvents: 'none',
          },
          '@keyframes shimmerEffect': {
            '0%': {
              transform: 'translateX(0%)',
            },
            '100%': {
              transform: 'translateX(200%)',
            },
          },
          borderColor: theme.palette.primary.main,
          boxShadow: `0 0 5px ${theme.palette.primary.main}`,
        })
      }}
    />
  );
};

// src/pages/Home.js
export default function Home() {

  const [fileName, setFileName] = React.useState<string>("");
  const [resumeUpdatedAt, setResumeUpdatedAt] = React.useState<string>("");

  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const [resumes, setResumes] = React.useState<any[]>([]);

  const [generateInProgress, setGenerateInProgress] = React.useState<boolean>(false);

  const [generationProgress, setGenerationProgress] = React.useState<number>(0);
  const [generationProgressMessage, setGenerationProgressMessage] = React.useState<string>("");

  const [jobDescription, setJobDescription] = React.useState<string>("");

  const [removeResumeInProgress, setRemoveResumeInProgress] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const params = useParams();

  const { userId, isAdmin } = useUser();

  const dispatch = useDispatch();

  const [isLoadingResumes, setIsLoadingResumes] = React.useState<boolean>(false);

  const hasResumeId = params.resumeB62Id!== UNPIN && params.resumeB62Id!== undefined;

  const [showAdvancedSettings, setShowAdvancedSettings] = React.useState<boolean>(false);

  const [resumeDetails, setResumeDetails] = React.useState<any>(null);

  const [settings, setSettings] = React.useState<ILlmAdvancedSettings>();

  const menuAnchorEl = React.useRef<any>(null);

  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  
  const [jobTitleOnlyMode, setJobTitleOnlyMode] = React.useState<boolean>(false);

  const theme = useTheme();

  const [activeStep, setActiveStep] = React.useState<number>(1);

  React.useEffect(() => {
    if (!hasResumeId) {
      setActiveStep(1);
    } else if (hasResumeId && !jobDescription) {
      setActiveStep(2);
    } else if (hasResumeId && jobDescription) {
      setActiveStep(3);
    }
  }, [hasResumeId, jobDescription]);

  React.useEffect(() => {
    setResumeDetails(null);

    setIsLoadingResumes(true);

    httpOnGetResumes(userId).then((res: any) => {
      setIsLoadingResumes(false);

      const resumes = res.data.results;
      setResumes(resumes);
      if (resumes && resumes.length) {
        
        if (params.resumeB62Id === UNPIN) {
          setMenuOpen(true);
        } else if (params.resumeB62Id) {
          const resume = resumes.find((resume: any) => resume.base62_id === params.resumeB62Id)
          if (resume) {
            setResumeDetails(resume)
          } else {
            navigate(`/app`);
          }
        } else {

          const resume = resumes[0];
          const resumeB62Id = resume.base62_id;
          setResumeDetails(resume);
          navigateToResume(resumeB62Id);

        }
      } else {
        if (params.resumeB62Id) {
          navigate(`/app`);
        }

        setMenuOpen(false);
      } 
    }).catch((e) => {
      setIsLoadingResumes(false);

      if (!e || e.status === 404) {
        setResumes([]);
      }
    })

    setGenerationProgressMessage('');
    setErrorMessage('');
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.resumeB62Id]);

  const handleMenuClickRemoveResume = async (resume: any) => {

    try {
      await removeResume(resume.base62_id)
      setResumes(resumes.filter((r: any) => r.base62_id!== resume.base62_id));
    } catch (e) {
      setRemoveResumeInProgress(false);
      return;
    }

  }

  const displayError = (errorMessage: string, errorMessageHtml: string) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessageHtml);
    setGenerateInProgress(false);
  }

  const steps = {
    started: {
      progress: 0,
      message: 'Firing up the ResumeFactory'
    },
    resumeResume: {
      progress: 2,
      message: 'Processing your uploaded resume',
      pollingUpdateIncrement: 1,
      progressWhenDone: 4
    },
    jobDescription: {
      progress: 4,
      message: 'Processing job description',
      pollingUpdateIncrement: 3,
      progressWhenDone: 24
    },
    tailoredResume: {
      progress: 24,
      message: 'Creating your Tailored Resume',
      nSubSteps: 10,
      progressWhenDone: 95
    },
    latex: {
      progress: 95,
      message: 'Structuring Resume Document'
    },
    done: {
      progress: 100,
      message: 'Done, Please see Resume PDF Resume in newly opened tab'
    }
  }

  function getPercentage(nthSubStep: number, progress: number, progressWhenDone: number, nSubSteps: number) {
    const progressPerStep = (progressWhenDone - progress) / (nSubSteps + 1)
    const result = progress + (nthSubStep + 1) * progressPerStep
    return result;
  }

  const setProgressStep = (step: any, isSubStep: boolean = false, nthSubStep: number = 0) => {


    let progress = step.progress
    if (isSubStep) {
      progress = getPercentage(nthSubStep, step.progress, step.progressWhenDone, step.nSubSteps);
    }
    setGenerationProgress(progress);

    // Use this code to set the progress bar correct percentages
    // const stepName = getKeyByValue(steps, step)
    // const trackingEvent = {
    //   timestamp: new Date(), stepName, nthSubStep, progress
    // }
    // console.log(trackingEvent);
    // // @ts-ignore
    // if (!window['_trackingEvents']) {window._trackingEvents = [];}
    // // @ts-ignore
    // window._trackingEvents.push(trackingEvent)
    // function getKeyByValue(object: any, value: string) {
    //   for (const [key, val] of Object.entries(object)) {
    //       if (val === value) return key;
    //   }
    // }

    setGenerationProgressMessage(step.message);
  }


  const generateResume = async () => {
    setProgressStep(steps.started);
    setErrorMessage('');
    setGenerateInProgress(true);
    setGenerationProgress(0);

    let jobB62Id: string = '';
    try {
      debugLogs && console.log('httpOnSaveJobDescription')
      const res: any = await httpOnSaveJobDescription(jobDescription, jobTitleOnlyMode, userId)
      jobB62Id = res.data.base62_id;
    } catch (e) {
      const errorMsg = 'We were unable to process the job description. Please check that you have pasted the complete job description and try again. If the problem persists, please contact our support team.';
      displayError(errorMsg, formatErrorMessageHtml(errorMsg, e))
      return;
    }

    setProgressStep(steps.resumeResume);

    let resumeB62Id = null;
    try {
      debugLogs && console.log('httpPollResumeExtracted')
      const res: any = await httpPollResumeExtracted(params.resumeB62Id!, userId, 3000, getProgressUpdateCallback(steps.resumeResume))
      resumeB62Id = res.data.base62_id;
    } catch (e) {
      const errorMsg = 'We were unable to process your uploaded resume. Please try uploading again. If you continue to experience issues, please contact our support team.';
      displayError(errorMsg, formatErrorMessageHtml(errorMsg, e))
      return;
    }

    setProgressStep(steps.jobDescription);

    try {
      debugLogs && console.log('httpPollJobDescriptionExtracted')
      const res: any = await httpPollJobDescriptionExtracted(jobB62Id, userId, 3000, getProgressUpdateCallback(steps.jobDescription))
    } catch (e) {
      const errorMsg = 'We were unable to analyze the job description. Please ensure you have pasted the complete job description text and try again. If you continue to experience issues, please contact our support team.';
      displayError(errorMsg, formatErrorMessageHtml(errorMsg, e))
      return;
    }

    setProgressStep(steps.tailoredResume);

    let tailoredB62Id: string = '';
    try {
      debugLogs && console.log('httpOnCreateTailored')
      const res: any = await httpOnCreateTailored(resumeB62Id, jobB62Id, userId)
      tailoredB62Id = res.data.base62_id;
    } catch (e: any) {
      let errorMsg = 'We were unable to create your tailored resume at this time. This could be due to system load or temporary issues. Please wait a minute and try again. If the problem persists, please contact our support team.';
      let errorMsgHtml = formatErrorMessageHtml(errorMsg, e);
      if (e?.data?.statusCode === 429) {
        errorMsg = 'Sorry, you have reached the maximum number of resumes that can be created in a 24 hour period.';
        errorMsgHtml = `<div>
        <p>Sorry, you have reached the maximum number of resumes that can be created in a 24 hour period.</p>
        <p>Please don't hesitate to contact our support if you need to create more resumes.</p>
        <p> Error message: ${e?.data?.message} </p>
        `
      }

      displayError(errorMsg, errorMsgHtml)
      return;
    }

    try {
      debugLogs && console.log('httpPollTailoredExtracted')
      // eslint-disable-next-line 
      const res: any = await httpPollTailoredExtracted(tailoredB62Id, userId, 3000,
        {status: '', statusChangedCounter: 0, detailedStatusCallback} as iDetailedStatusContextWithCallback)
    } catch (e) {
      const errorMsg = 'We were unable to complete your tailored resume. This could be due to an issue with the content analysis. Please try again, and if the problem continues, contact our support team for assistance.';
      displayError(errorMsg, formatErrorMessageHtml(errorMsg, e))
      return;
    }

    setProgressStep(steps.latex);

    const templateB62Id = null; // use default template, override if templateB62Id is provided
    let latexB62Id: string = '';
    try {
      debugLogs && console.log('httpOnCreateLatex')

      const res: any = await httpOnCreateLatex(tailoredB62Id, templateB62Id, null, userId);

      latexB62Id = res.data.base62_id;
    } catch (e) {
      const errorMsg = 'Sorry, something went wrong while structuring the resume document.';
      displayError(errorMsg, formatErrorMessageHtml(errorMsg, e))
      return;
    }

    setProgressStep(steps.done);

    setTimeout(() => {
      setGenerateInProgress(false);

      // navigate(`/app/${params.resumeB62Id}/job/${jobB62Id}/tailored-resume/${tailoredB62Id}`);
      navigate(`/app/${params.resumeB62Id}/job/${jobB62Id}/tailored-resume/${tailoredB62Id}/latex/${latexB62Id}`);
    }, 2000);

  }

  const detailedStatusCallback = (status: string, statusChangedCounter: number) => {
    debugLogs && console.log(`httpPollTailoredExtracted detailedStatusCallback status: ${status}`)

    setProgressStep(steps.tailoredResume, true, statusChangedCounter);

    if (status) {
      setGenerationProgressMessage(status);
    }

  };

  const getProgressUpdateCallback = (step: any) => () => {
    debugLogs && console.log(`progressUpdateCallback step: ${step}`)
    
      setGenerationProgress((generationProgress: number) => {
        if (generationProgress < step.progressWhenDone - step.pollingUpdateIncrement - 1) {
          debugLogs && console.log(`update progress: ${generationProgress + step.pollingUpdateIncrement}`)
          return generationProgress + step.pollingUpdateIncrement
        }
        return generationProgress
    })
  };

  const removeResume = async (resumeB62Id: string, shouldNavigateToHome: boolean = false) => {
    setRemoveResumeInProgress(true);
    try {
      await httpOnDeleteResume(resumeB62Id, userId)
    } catch (e) {
      setRemoveResumeInProgress(false);
      return;
    }
    setRemoveResumeInProgress(false);
    if (shouldNavigateToHome) {
      navigate('/app');
    }
  }

  const editResume = (resumeB62Id: string)  =>  navigate(`/app/${resumeB62Id}/edit`);

  const isResumeStatusFailed = () => { // has validation errors
    return resumeDetails?.status ===	"Failed";
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOpen(true);
  };
  
  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleDragOver = (e: any) => {
    setMenuOpen(false);
  };

  useEffect(() => {
    document.addEventListener('dragover', handleDragOver);
    
    return () => {
      document.removeEventListener('dragover', handleDragOver);
    };
  }, []);

  const handleUploadCompleted = (resumeB62Id: string, resumeUpdatedAt: string) => {
    setResumeUpdatedAt(formatUpdatedAt(resumeUpdatedAt));
    navigateToResume(resumeB62Id);
  }

  const handleUploadCompletedWithResumeValidationError = (resumeB62Id: string, resumeUpdatedAt: string) => {
    // setResumeUpdatedAt(formatUpdatedAt(resumeUpdatedAt));
    editResume(resumeB62Id);
  }

  const selectResume = (resume: any) => {
    const resumeB62Id = resume.base62_id;
    setResumeDetails(resume);
    navigateToResume(resumeB62Id);
  }

  useEffect(() => {
    if (resumeDetails) {  
      const resumeUpdatedAt = resumeDetails.updated_at;
      setFileName(resumeDetails.file_name);
      setResumeUpdatedAt(formatUpdatedAt(resumeUpdatedAt));
    }
  }, [resumeDetails]);

  const navigateToResume = (resumeB62Id: string) => {
    navigate(`/app/${resumeB62Id}`);
  }

  const navigateUnpin = () => {
    navigate(`/app/${UNPIN}`);
  }

  return (
    <Box pt={3}>
      <Box pb={3}>
        <Box>
          { hasResumeId && 
          <Box>
            <Box sx={{p: 1, pb: 2, display: 'flex', gap: 2}}>
              <Tooltip title={'Use another resume'} placement="top" arrow>
                <Button variant='outlined' color={'primary'}
                  onClick={() => navigateUnpin()} 
                  disabled={generateInProgress || removeResumeInProgress}
                  startIcon={<ArrowBack/>}>
                  Use another resume
                </Button>
              </Tooltip>
              
              <Tooltip title={resumeDetails?.tailor_count > 0 
                ? 'View all tailored resumes created from current resume' 
                : 'No tailored resumes created yet'} placement="top" arrow>
                <span>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`/app/${params.resumeB62Id}/folder`)}
                    disabled={generateInProgress || !resumeDetails?.tailor_count}
                    startIcon={<GridViewSharpIcon/>}
                  >
                    View tailored resumes
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
          }

          { generateInProgress && <Box py={2}>
            <LinearProgress variant="determinate" value={generationProgress} sx={{
              height: '4px',
              borderRadius: '2px'
            }} />
          </Box> }

          { errorMessage ?
            <Box pb={2}>
              <Alert severity="error">
                <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
              </Alert>
            </Box> :
            <Box pb={2}>
              <Typography color={'primary'}>
              { generateInProgress && <CircularProgress sx={{
                marginBottom: '-3px', marginRight: '8px',
                width: '20px !important', height: '20px !important'}}/> }
                {generationProgressMessage}
              </Typography>
            </Box>
          }

          { !hasResumeId &&
          <Box>
            <Box display={(!hasResumeId && resumes.length === 0 && isLoadingResumes) ? 'unset' : 'none'}>
              <Box py={2}>
                <LinearProgress sx={{
                  height: '4px',
                  borderRadius: '2px'
                }}/>
              </Box>
            </Box>
          </Box>
          }

          { isResumeStatusFailed() &&
            <Alert severity='error'>
              <Typography variant="body1">
                Selected resume has validation errors, please edit and fix errors to be able to proceed with the next steps.
              </Typography>
              <Button
                  onClick={() => editResume(params.resumeB62Id!)}
                  color='error'
                  variant="outlined"
                  sx={{mt: 1}}
                >
                  Edit Resume
                </Button>
            </Alert>
          }

          <Box pb={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Box sx={{p: 1, width: theme.spacing(48), height: '100%'}}>
                  <Box sx={{ mb: 2 }}>
                    <StepChip
                      label={(!hasResumeId && resumes.length > 0) 
                        ? "Please upload or choose resume" 
                        : "Please upload resume"}
                      completed={hasResumeId}
                      active={activeStep === 1}
                      icon={<Upload />}
                      stepNumber={1}
                    />
                  </Box>

                  { !hasResumeId && 
                    <Box>
                      <FileUpload
                        onUploadCompleted={handleUploadCompleted}
                        onSetFileName={(fileName: string) => setFileName(fileName)}
                        onUploadCompletedWithResumeValidationError={handleUploadCompletedWithResumeValidationError}
                      />
                      <Box display={(!hasResumeId && resumes.length > 0) ? 'block' : 'none'} pt={2}>
                        <Button
                          id="demo-positioned-button"
                          aria-controls={menuOpen ? 'demo-positioned-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={menuOpen ? 'true' : undefined}
                          onClick={handleMenuClick}
                          ref={menuAnchorEl}
                        >
                          Choose Previously Uploaded Resume
                        </Button>
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={menuAnchorEl.current}
                          open={menuOpen}
                          onClose={handleMenuClose}
                          sx={{height: theme.spacing(40)}}
                        >
                          {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography pl={2} >Choose Previously Uploaded Resume</Typography>
                            <IconButton onClick={(event) => {handleMenuClose()}}><Close/></IconButton>
                          </Box>
                          <Divider /> */}
                          <MenuList>
                            {resumes.map(resume  => (
                              <MenuItem onClick={() => {selectResume(resume); handleMenuClose();}} key={resume.base62_id} >
                                {/* <ListItemIcon>
                                  <Check />
                                </ListItemIcon> */}
                                {resume.file_name}
                                <Typography> &nbsp;&#x2022;&nbsp; </Typography>
                                <Typography color="grey">{formatUpdatedAt(resume.updated_at)}</Typography>

                                <IconButton color={'error'} sx={{marginLeft: 'auto', marginRight: '-10px'}}
                                  onClick={(event) => {event.stopPropagation();handleMenuClickRemoveResume(resume)}} disabled={generateInProgress || removeResumeInProgress}><DeleteForever/></IconButton>
                              </MenuItem>  
                            ))}
                          </MenuList>
                        </Menu>
                      </Box>
                    </Box>
                  }

                  { hasResumeId && 
                    <ResumeCard
                      resumeB62Id={params.resumeB62Id!}
                      fileName={fileName}
                      resumeUpdatedAt={resumeUpdatedAt}
                      onRemoveResume={(resumeB62Id: string) => removeResume(resumeB62Id, true)}
                      removeResumeInProgress={removeResumeInProgress}
                      isDisabled={generateInProgress}
                      displayNavigateToResources={false}
                      isResumeStatusFailed={isResumeStatusFailed()}
                      tailor_count={resumeDetails?.tailor_count}
                      onViewTailoredResumes={() => navigate(`/app/${params.resumeB62Id}/folder`)}
                      showQuickViewButton={true}
                    />
                  }
                </Box>
              </Grid>

              <Grid item xs={12} lg={8}>
                <Box sx={{ 
                  p: 1, 
                  pt: { xs: theme.spacing(6), lg: 1 }
                }}>
                  <Box sx={{ mb: 2 }}>
                    <StepChip
                      label="Please paste job description from job board or company website"
                      completed={!!jobDescription}
                      active={activeStep === 2}
                      icon={<Description />}
                      stepNumber={2}
                    />
                  </Box>

                  <Box sx={{ 
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2 
                  }}>
                    <form>
                      { isAdmin === true && 
                        <FormControlLabel
                          sx={{ paddingLeft: '80px' }}
                          control={<Switch value={jobTitleOnlyMode} onChange={(event) => setJobTitleOnlyMode(event.target.checked)}/>}
                          label="Job title only" 
                        />
                      }
                      <Textarea
                        aria-label="job description textarea"
                        placeholder={jobTitleOnlyMode ? "Please paste the Job Title here": "Please paste the Job Description here"}
                        maxRows={5}
                        disabled={generateInProgress || isResumeStatusFailed()}
                        sx={{
                          width: '100%',
                          minHeight: '300px',
                          ...textFieldStyle
                        }}
                        onChange={(event: any) => setJobDescription(event.target.value)}
                      />
                    </form>

                    <Box sx={{
                      position: 'relative',
                      zIndex: 1,
                      mt: 0,
                      display: 'inline-block',
                      lineHeight: 0
                    }}>
                      <Button
                        variant="contained"
                        endIcon={<AutoAwesome />}
                        disabled={!hasResumeId || !jobDescription || generateInProgress}
                        onClick={generateResume}
                        sx={{
                          ...theme.button.primary,
                          width: {md: 340},
                          fontSize: '16px',
                          position: 'relative',
                          overflow: 'hidden',
                          ...((activeStep === 3 && !generateInProgress) && {
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: '-100%',
                              width: '100%',
                              height: '100%',
                              background: `linear-gradient(90deg, transparent, ${theme.palette.primary.main}22, rgba(255,255,255,0.42))`,
                              animation: 'shimmerEffect 2.5s infinite linear',
                              filter: 'blur(3px)',
                              pointerEvents: 'none',
                              opacity: 0.73,
                              borderRadius: 'inherit',
                            },
                            '@keyframes shimmerEffect': {
                              '0%': {
                                transform: 'translateX(0%)',
                              },
                              '100%': {
                                transform: 'translateX(200%)',
                              },
                            },
                            boxShadow: `0 0 3px ${theme.palette.primary.main}`,
                          })
                        }}
                      >
                        {`Step 3: Generate Tailored Resume`}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box pt={9}></Box>

          { isAdmin &&
            <Box pb={3}>
              {!showAdvancedSettings && <Button endIcon={<Settings/>} onClick={() => setShowAdvancedSettings(true)}>Show advanced settings </Button>}
              {showAdvancedSettings && <LlmAdvancedSettings onChange={setSettings} firstOpen={true}/>}
            </Box>
          }

        </Box>
      </Box>

      <Box pb={3}></Box>

      <FeedbackSidePopup></FeedbackSidePopup>

    </Box>
  );
}